import { creatorTimezone$ } from '@/util/subjects';

export default {
  locked: false,
  types: [],
  categories: [],
  teams: [],
  activeTags: [],
  activeFilterTags: [],
  date: null,
  searchTerm: '', // used debounced for API SDK
  recommended: false,
  sortOrder: 'published_desc',
  activeDropDownFilter: null,
  updateAvailable: false,
  gridLoading: true,
  gridInited: false,
  isMobile: false,
  mobileFilterMenuVisible: false,
  results: null,
  categoryList: [],
  teamList: [],
  cupProfile: null,
  currentLocale: 'en_US',
  activePosting: null,
  activePostingFlickingIndex: 0,
  authInitialised: false,
  creatorTimezone: creatorTimezone$.value,
  initialPostingsLimit: 40,
  loadMoreLimit: 10,
  activeTab: 'main',
  supportsNotifications: false,
};
