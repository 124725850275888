<template>
  <div
    class="smp-dropdownFilter smp-multiSelectFilter"
    :class="{'is-open': isOpen }">
    <div
      class="smp-dropdownFilter-toggle smp-multiSelectFilter-toggle smp-button smp-button-primary"
      @click="toggle">
      <div class="flex-auto flex items-center">
        <i
          v-if="icon"
          class="mr2">
          <fa-icon :icon="icon" />
        </i>

        {{ label }} <span class="ms-1 ml1">({{ activeOptionsCount }})</span>
      </div>
      <div class="smp-dropdownFilter-toggleIndicator smp-multiSelectFilter-toggleIndicator">
        <fa-icon :icon="['fas', 'angle-down']" />
      </div>
    </div>

    <div
      v-show="isOpen"
      class="smp-dropdownFilter-content smp-multiSelectFilter-content">
      <div
        v-if="canSearch"
        class="smp-multiSelectFilter-search">
        <input
          ref="searchField"
          type="text"
          :placeholder="$t('filter.selectFilter.searchPlaceholder')"
          v-model="searchTerm">
      </div>
      <div
        class="smp-dropdownFilter-options smp-multiSelectFilter-options">
        <div
          v-for="section in preparedOptions"
          :key="section.key"
          class="smp-multiSelectFilter-optionSection">
          <div
            class="smp-dropdownFilter-option smp-multiSelectFilter-option"
            v-for="option in section.options"
            :class="{ disabled: option.available === false }"
            :key="option.value">
            <checkbox
              :id="`${option.value}`"
              :value="option.active"
              @input="toggleValue(option)">
              <posting-type-icon
                v-if="usePostingTypeIcons"
                :type="option.value" />

              <template v-if="!usePostingTypeIcons && option.icon">
                <fa-icon
                  v-if="option.icon && Array.isArray(option.icon)"
                  class="mr2"
                  :icon="option.icon" />
                <span
                  v-if="typeof option.icon === 'string'"
                  class="mr2"
                  v-html="option.icon" />
              </template>

              {{ option.label }}
            </checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownMixin from '@/mixins/DropDown';
import Checkbox from '@/components/Checkbox.vue';
import PostingTypeIcon from '@/components/PostingTypeIcon.vue';
import { cloneObject, sortArray } from '@/util/utils';

/**
   * Class for a single "multi filter".
   *
   * Currently just a list of checkboxes, but could also be for example a multi select control.
   */
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    filterKey: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
      required: false,
      default: null,
    },
    usePostingTypeIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
    sort: {
      type: [Object, Boolean],
      required: false,
      default: () => ({
        field: 'label',
        order: 'asc',
      }),
    },
    searchEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    searchOptionsLimit: {
      type: Number,
      required: false,
      default: 10,
    },
    groupByState: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      containerClass: 'smp-multiSelectFilter',
      searchTerm: '',
    };
  },
  mixins: [DropDownMixin],
  components: { Checkbox, PostingTypeIcon },
  computed: {
    activeOptionsCount() {
      return this.options.filter((option) => option.active).length;
    },
    preparedOptions() {
      let options = cloneObject(this.options);

      if (this.sort && this.sort.field && this.sort.order) {
        options = sortArray(options, this.sort.field, this.sort.order);
      }

      if (this.canSearch && this.searchTerm && typeof this.searchTerm === 'string' && this.searchTerm.length > 0) {
        const term = this.searchTerm.toLowerCase();

        options = options.filter((option) => option.label.toLowerCase().indexOf(term) > -1);
      }

      if (!this.groupingEnabled) {
        return [
          {
            key: 'availableOptions',
            options,
          },
        ];
      }

      const response = [];
      const availableOptions = options.filter((option) => option.available);
      const inactiveOptions = options.filter((option) => !option.available);

      if (availableOptions.length > 0) {
        response.push({
          key: 'availableOptions',
          options: availableOptions,
        });
      }

      if (inactiveOptions.length > 0) {
        response.push({
          key: 'inactiveOptions',
          headline: 'Inactive Options',
          options: inactiveOptions,
        });
      }

      return response;
    },
    canSearch() {
      return this.searchEnabled && this.options && this.options.length > this.searchOptionsLimit;
    },
    groupingEnabled() {
      return this.groupByState;
    },
  },
  watch: {
    isOpen() {
      // Try to autofocus the search input field if search is enabled
      if (this.isOpen && this.canSearch) {
        this.$nextTick(() => {
          if (this.$refs.searchField) {
            this.$refs.searchField.focus();
          }
        });
      }
    },
  },
  methods: {
    toggleValue(option) {
      if (this.filterKey === 'categories') {
        this.$store.dispatch('toggleCategory', option.value);
      } else if (this.filterKey === 'types') {
        this.$store.dispatch('toggleType', option.value);
      } else if (this.filterKey === 'teams') {
        this.$store.dispatch('toggleTeam', option.value);
      } else if (this.filterKey === 'tags') {
        this.$store.dispatch('toggleTag', option.value);
      } else if (this.filterKey === 'filterTags') {
        this.$store.dispatch('toggleFilterTag', option.value);
      }
    },
  },
};
</script>
