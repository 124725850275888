<template>
  <div class="smp-searchField">
    <i>
      <fa-icon :icon="['fas', 'search']" />
    </i>
    <input
      type="search"
      :value="searchTerm"
      @input="input"
      :placeholder="$t('filter.search.placeholder')">
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from '@/util/utils';

export default {
  computed: {
    ...mapGetters(['searchTerm']),
  },
  methods: {
    ...mapActions(['setSearchTerm']),
    input(event) {
      this.setTerm(event.target.value, this);
    },
    setTerm: debounce((value, context) => {
      context.setSearchTerm(value);
    }, 400),
  },
};
</script>

<style lang="less">
  @import (reference) "~@/styles/vars";

  .smp-searchField {
    width: 100%;
    max-width: 600px;
    border: 1px solid @color-mono-50;
    border-radius: @defaultBorderRadius;
    position: relative;

    i {
      position: absolute;
      left: @space-2;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      border: 0;
      width: 100%;
      background: none;
      padding: @space-2 @space-2 @space-2 @space-2 + @space-4;
    }
  }
</style>
