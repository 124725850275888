<template>
  <transition name="fade">
    <div
      class="smp-postingPreview smp-overlay"
      :class="`category-${posting.type}`">
      <!-- Loading animation -->
      <div
        class="smp-postingPreview--loading"
        v-if="loading">
        <fa-icon
          :icon="['fas', 'spinner']"
          spin />
      </div>

      <div
        class="smp-postingPreview-noClose"
        v-if="!loading && !closing">
        <!-- Prev/Next navigation buttons -->
        <div
          class="smp-postingPreview-navigation smp-postingPreview-prev"
          :class="{disabled: !prevPost || updating}"
          v-if="navigationVisible">
          <button
            class="smp-button"
            @click.prevent="goToPrevPost">
            <fa-icon :icon="['fas', 'angle-left']" />
          </button>
        </div>
        <div
          class="smp-postingPreview-navigation smp-postingPreview-next"
          :class="{disabled: !nextPost || updating}"
          v-if="navigationVisible">
          <button
            class="smp-button"
            @click.prevent="goToNextPost">
            <fa-icon :icon="['fas', 'angle-right']" />
          </button>
        </div>

        <!-- Loader shown during content/posting update -->
        <div
          class="smp-postingPreview--updating"
          v-if="showUpdateLoader">
          <fa-icon
            :icon="['fas', 'spinner']"
            spin />
        </div>
      </div>

      <transition name="zoom">
        <div
          class="smp-postingPreview-wrapper smp-postingPreview-noClose"
          :class="{updating: showUpdateLoader}"
          v-show="!loading && !closing">
          <!-- Close btn-->
          <div
            class="smp-postingPreview-closeBtn"
            @click="close()">
            <fa-icon :icon="['fas', 'times']" />
          </div>

          <div class="smp-postingPreview-content smp-overlay-boxShadow">
            <div class="smp-postingPreview-media flex-auto">
              <template
                v-if="isImage || isInfoGraphic">
                <flicking
                  ref="imageSlider"
                  v-if="previewImages.length > 1"
                  :plugins="plugins"
                  :options="{ defaultIndex: activePostingFlickingIndex }"
                  :key="`postingPreviewImage-${posting.id}-${currentLocale}`"
                  @ready="loaded()">
                  <div
                    class="smp-flickingPane"
                    v-for="image in previewImages"
                    :key="image.id"
                    @click="openPreview()">
                    <img :src="image.url">
                  </div>
                  <div
                    slot="viewport"
                    class="flicking-pagination" />
                  <span
                    slot="viewport"
                    class="flicking-arrow-prev" />
                  <span
                    slot="viewport"
                    class="flicking-arrow-next" />
                </flicking>

                <img
                  :alt="title"
                  :src="posting.image.url"
                  v-else
                  @load="loaded()">
              </template>

              <video-slider
                v-if="isMultiClip"
                ref="videoSlider"
                :key="posting.id"
                :posting="posting"
                :destroyed="destroyed"
                @loaded="loaded"
                @error="close" />

              <video-player
                v-if="isClip && !isMultiClip"
                ref="videoPlayer"
                :key="primaryAsset.uuid"
                :posting="posting"
                :asset="primaryAsset"
                :destroyed="destroyed"
                @loaded="loaded"
                @error="close"
                autoplay />

              <template v-if="is360Clip">
                <player-360
                  :key="posting.id"
                  :posting="posting"
                  @loaded="loaded" />
              </template>
            </div>

            <div class="smp-postingPreview-data flex-none pb3">
              <div class="smp-postingPreview-dataWrapper flex flex-wrap">
                <div class="smp-dataField col-12">
                  <div class="smp-dataField-label">
                    {{ $t('posting.preview.headlines.title') }}
                  </div>
                  <div class="smp-dataField-value font-normal font-highlight">
                    {{ title }}
                  </div>
                </div>

                <div
                  class="smp-dataField smp-dataField-br"
                  :class="[{ 'col-12 xs-col-6': posting.category && showCategoryInfo }, { 'col-12': !posting.category || !showCategoryInfo }]">
                  <div class="smp-dataField-label">
                    {{ $t('posting.preview.headlines.postingType') }}
                  </div>
                  <div class="smp-dataField-value">
                    <posting-type-icon
                      class="mr2"
                      :type="posting.type" />
                    {{ $t(`posting.type.${posting.type.toLowerCase()}`) }}
                  </div>
                </div>

                <div
                  class="smp-dataField col-12 xs-col-6"
                  v-if="posting.category && showCategoryInfo">
                  <div class="smp-dataField-label">
                    {{ $t('posting.preview.headlines.category') }}
                  </div>
                  <div class="smp-dataField-value">
                    {{ category }}
                  </div>
                </div>

                <div
                  class="smp-dataField col-12"
                  v-if="teams && teams.length > 0">
                  <div class="smp-dataField-label">
                    {{ $t(`generic.contestants.${contestantType}.plural`) }}
                  </div>
                  <div class="smp-dataField-value flex flex-wrap mxn1">
                    <span
                      class="smp-tag m1"
                      v-for="team in teams"
                      :key="team">
                      <team :code="team" />
                    </span>
                  </div>
                </div>

                <div
                  class="smp-dataField col-12"
                  v-if="posting.tags && posting.tags.length > 0">
                  <div class="smp-dataField-label">
                    {{ $t('posting.preview.headlines.tags') }}
                  </div>
                  <div class="smp-dataField-value flex flex-wrap mxn1">
                    <span
                      class="smp-tag m1"
                      v-for="tag in posting.tags"
                      :key="tag">
                      {{ tag }}
                    </span>
                  </div>
                </div>

                <div class="smp-dataField col-12 xs-col-6">
                  <div class="smp-dataField-label">
                    {{ $t('posting.preview.headlines.userTimezone') }}
                  </div>
                  <div class="smp-dataField-value">
                    {{ publishedDateInUserTimezone }}
                  </div>
                </div>

                <div class="smp-dataField col-12 xs-col-6">
                  <div class="smp-dataField-label">
                    {{ creatorTimezoneLabel }}
                  </div>
                  <div class="smp-dataField-value">
                    {{ publishedDateInCreatorTimezone }}
                  </div>
                </div>

                <template v-if="imageResolution && (isImage || isInfoGraphic)">
                  <div
                    class="smp-dataField col-12">
                    <div class="smp-dataField-label">
                      {{ $t('posting.preview.headlines.resolution') }}
                    </div>
                    <div class="smp-dataField-value">
                      {{ imageResolution }}
                    </div>
                  </div>
                </template>

                <div
                  v-if="primaryAsset.size"
                  class="smp-dataField col-12"
                  :class="{'xxs-col-6': showDownloadCounts }">
                  <div class="smp-dataField-label">
                    {{ $t('posting.preview.headlines.filesize') }}
                  </div>
                  <div class="smp-dataField-value">
                    {{ primaryAsset.size }}
                  </div>
                </div>

                <div
                  v-if="showDownloadCounts"
                  class="smp-dataField col-12 xxs-col-6">
                  <div class="smp-dataField-label">
                    {{ $t('posting.downloads') }}
                  </div>
                  <div class="smp-dataField-value">
                    {{ downloads }}
                  </div>
                </div>

                <div
                  v-if="accessPermissions.length > 0"
                  class="smp-dataField col-12">
                  <div class="smp-dataField-label">
                    {{ $t('posting.preview.headlines.accessPermissions') }}
                    <div class="ms-2 text-muted">
                      {{ $t('posting.preview.accessPermissionInfo') }}
                    </div>
                  </div>
                  <div class="smp-dataField-value flex flex-wrap mxn1">
                    <span
                      class="smp-tag smp-permission-tag m1"
                      v-for="permission of accessPermissions"
                      :key="permission.id">
                      {{ permission.title }}
                      <tooltip
                        v-if="permission.info"
                        placement="right"
                        :content="permission.info"
                        :delay="{ show: 500, hide: 0 }">
                        <i class="ml1">
                          <fa-icon :icon="['fas', 'info-circle']" />
                        </i>
                      </tooltip>
                    </span>
                  </div>
                </div>
              </div>

              <div class="smp-postingPreview-downloadActionWrapper flex justify-center mt4">
                <download-button
                  :posting="posting"
                  button-classes="smp-button smp-button-primary smp-button--large" />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { Pagination, Arrow } from '@egjs/flicking-plugins';
import Overlay from '@/mixins/Overlay';
import PostingMixin from '@/mixins/Posting';
import VideoPlayer from '@/components/VideoPlayer.vue';
import VideoSlider from '@/components/VideoSlider.vue';
import Player360 from '@/components/Player360.vue';
import DownloadButton from '@/components/DownloadButton.vue';
import Team from '@/components/Team.vue';
import PostingTypeIcon from '@/components/PostingTypeIcon.vue';
import Tooltip from '@/components/Tooltip.vue';

let updateLoaderTimeout;

export default {
  data() {
    return {
      containerClass: 'smp-postingPreview-noClose',
      loading: true,
      signedClipUrl: null,
      destroyed: false,
      closing: false,
      updating: false,
      showUpdateLoader: false,
      plugins: [new Pagination({ type: 'bullet' })],
    };
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VideoPlayer, VideoSlider, Player360, DownloadButton, Team, Tooltip, PostingTypeIcon,
  },
  mixins: [Overlay, PostingMixin],
  computed: {
    ...mapState(['activePostingFlickingIndex', 'cupProfile']),
    ...mapGetters(['postings', 'creatorTimezoneLabel', 'showDownloadCounts', 'currentLang', 'showCategoryInfo', 'contestantType']),
    currentPostingIndex() {
      if (!this.postings) return -1;
      return this.postings.findIndex((p) => p.id === this.posting.id);
    },
    nextPost() {
      if (this.currentPostingIndex === -1) return null;
      const nextPost = this.postings[this.currentPostingIndex + 1];

      return typeof nextPost !== 'undefined' ? nextPost : null;
    },
    prevPost() {
      if (this.currentPostingIndex === -1) return null;
      const prevPost = this.postings[this.currentPostingIndex - 1];

      return typeof prevPost !== 'undefined' ? prevPost : null;
    },
    navigationVisible() {
      return !this.isPreview && (this.nextPost || this.prevPost);
    },
    accessPermissions() {
      return this.posting.requiredAccessPermissions
        .map((permissionId) => this.cupProfile.accessPermissions.find((permission) => permission.id === permissionId))
        .map((permission) => {
          const info = (() => {
            const targetLang = Object
              .keys(permission.info)
              .find((lang) => lang.startsWith(`${this.currentLang}_`));

            return targetLang ? permission.info[targetLang] : null;
          })();

          return {
            ...permission,
            info,
          };
        });
    },
  },
  methods: {
    ...mapActions(['getSignedUrl', 'download']),
    init() {
      if (!this.isPreview) history.replaceState({}, null, this.postingLink);
    },
    loaded() {
      this.loading = false;
      this.updating = false;

      if (this.$refs.imageSlider) {
        // Wait for the zoom animation to finish
        setTimeout(() => {
          if (this.$refs.imageSlider) {
            // Add the arrow plugin only now, as the disabled state will be false if added before
            this.$refs.imageSlider.addPlugins(new Arrow());
          }
        }, 250);
      }
    },
    handleKeyup(e) {
      if (e.keyCode === 27) this.close();
      if (e.keyCode === 37 && this.prevPost) this.goToPrevPost();
      if (e.keyCode === 39 && this.nextPost) this.goToNextPost();
    },
    close() {
      this.closing = true;
      setTimeout(() => {
        if (!this.isPreview) history.replaceState({}, null, location.origin);
        this.$emit('close');
      }, 100);
    },
    goToPrevPost() {
      this.goToPost(this.prevPost);
    },
    goToNextPost() {
      this.goToPost(this.nextPost);
    },
    goToPost(post) {
      if (!this.navigationVisible) return;
      this.updating = true;

      if (this.isMultiClip && this?.$refs?.videoSlider) {
        this.$refs.videoSlider.stopVideo();
      }

      if (this.isClip && !this.isMultiClip && this?.$refs?.videoPlayer) {
        this.$refs.videoPlayer.stopVideo();
      }

      this.$store.dispatch('setActivePosting', post);
    },
  },
  beforeDestroy() {
    // Set destroyed flag to prevent the player from starting as soon as the canplay listener fires
    this.destroyed = true;
  },
  watch: {
    posting() {
      this.init();
    },
    updating(value) {
      if (value) {
        this.signedClipUrl = null;
        updateLoaderTimeout = setTimeout(() => {
          this.showUpdateLoader = true;
        }, 400);
      } else {
        this.showUpdateLoader = false;
        clearTimeout(updateLoaderTimeout);

        if (this.isClip && this.$refs.player) {
          this.$refs.player.load();
        }
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="less">
  @import (reference) '~@/styles/base';
  @import '~@/styles/overlay';

  .smp-postingPreview {
    .smp-postingPreview--loading {
      font-size: 50px;
      color: @color-primary;
      width: 70px;
      height: 70px;
    }

    .smp-postingPreview--updating {
      font-size: 50px;
      color: @color-primary;
      width: 70px;
      height: 70px;
      position: fixed;
      z-index: 9092;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .smp-postingPreview-wrapper {
      position: relative;
      max-width: calc(100% - 2 * 50px - 2 * @space-6);
      max-height: 90%;

      &.updating {
        pointer-events: none;
        opacity: 0.7;
        filter: blur(2px);
      }
    }

    .smp-postingPreview-closeBtn {
      position: absolute;
      z-index: 90;
      cursor: pointer;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: @color-mono-100;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3),
        0 3px 1px -2px rgba(0, 0, 0, 0.5),
        0 1px 5px 0 rgba(0, 0, 0, 0.3);
    }

    .smp-postingPreview-navigation {
      position: fixed;
      z-index: 9091;
      top: 50%;
      transform: translateY(-50%);

      .smp-button {
        height: 50px;
        width: 50px;
        font-size: 30px;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &.smp-postingPreview-next {
        right: 0;
      }
      &.smp-postingPreview-prev {
        left: 0;
      }
    }

    .smp-postingPreview-content {
      display: flex;

      .smp-postingPreview-media {
        background: @color-mono-0;
        display: flex;
        align-items: center;

        img, video {
          display: block;
          max-width: 100%;
          max-height: 90vh;
        }

        video {
          background: @color-mono-0;
        }

        .flicking-viewport {
          .flicking-camera {
            .smp-flickingPane {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .smp-postingPreview-data {
        background: @color-mono-100;
        width: 30vw;

        .smp-dataField {
          padding: @space-3;
          border-bottom: 1px solid @color-mono-80;

          &.smp-dataField-br {
            border-right: 1px solid @color-mono-80;
          }
        }

        .smp-dataField-label {
          margin-bottom: @space-1;
          .ms(-1)
        }

        .smp-dataField-value {
          font-weight: 600;
          .ms(0);
        }

        .smp-permission-tag:hover {
          cursor: default;
        }
      }
    }

    @media screen and (max-width: (@breakpoint-lg - 1)) {
      .smp-postingPreview-wrapper {
        display: flex;
        max-width: calc(100% - 2 * 50px - 2 * @space-3);
      }

      .smp-postingPreview-content {
        .flex-auto;
        overflow: hidden;
        flex-direction: column;

        .smp-postingPreview-media {
          .flex-none;
          max-height: 40vh;
          justify-content: center;

          img, video {
            max-height: 40vh;
          }
        }

        .smp-postingPreview-data {
          width: 100%;
          .flex-auto;
          display: flex;
          flex-direction: column;

          .smp-dataField.smp-dataField-br {
            border-right: none;
          }

          .smp-postingPreview-dataWrapper {
            .flex-auto;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
          }

          .smp-postingPreview-downloadActionWrapper {
            .flex-none;
          }
        }
      }
    }
  }
</style>
