import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const namespaced = true;

const state = {
  user: null,
  userTimezone: null,
  userIdTokenResult: null,
  userProfile: null,
  userProfileUnsubscribeFn: null,
  isAccountViewVisible: false,
  isOnboardingVisible: false,
};

export {
  actions, mutations, state, getters,
};
