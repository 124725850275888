import {
  LOG_ACTION_DISMISS,
  LOG_ACTION_PREVIEW,
  LOG_ENTITY_POSTING,
  backendService,
} from '@/services/BackendService';
import LogService from '@/services/LogService';
import { debounce } from '@/util/utils';
import logger from '@/util/logger';
import * as types from './mutation-types';
import { getCollectionObserver, getDocumentObserver } from '@/util/firebase';
import {
  creatorTimezone$,
  postingsToAdd$,
  postingsToDelete$,
} from '@/util/subjects';
import router from '@/router';

export default {
  async fetchPostings({ state, commit, dispatch }, {
    fetchParams, callback, errCallback,
  }) {
    try {
      if (!fetchParams.limit) {
        fetchParams.limit = state.initialPostingsLimit;
      }
      const results = await backendService.postings(fetchParams);

      commit(types.SET_RESULTS, {
        postings: results.hits,
        total: results.total,
        aggregations: results.aggregations,
      });
      dispatch('saveSession');

      if (callback) {
        callback(results.hits, results.total);
      }
    } catch (e) {
      logger.captureException(e);
      errCallback(e);
    }
  },
  async loadMore({ state, getters, commit }, {
    fetchParams, callback, errCallback,
  }) {
    try {
      if (!fetchParams.limit) {
        fetchParams.limit = state.loadMoreLimit;
      }

      fetchParams.search_after = getters.postings[getters.postings.length - 1].sort;

      const results = await backendService.postings(fetchParams);

      // Prevent duplicates (can happen if the posting order changes)
      const existingIds = getters.postings.map((p) => p.id);
      const hits = results.hits.filter((hit) => !existingIds.includes(hit.id));

      commit(types.ADD_POSTINGS, hits);

      if (results.aggregations) {
        commit(types.SET_AGGREGATIONS, results.aggregations);
      }

      if (callback) {
        callback(hits, results.total);
      }
    } catch (e) {
      errCallback(e);
    }
  },
  getSignedUrl(_, { assetId }) {
    return backendService.getSignedUrl(assetId);
  },
  createTrackingLog(_, trackingData) {
    backendService.log(trackingData);
  },
  lock({ commit }) {
    commit(types.LOCK);
  },
  unlock({ commit }) {
    commit(types.UNLOCK);
  },
  toggleType({ commit }, type) {
    LogService.event('Navigation', 'toggleType', type);
    commit(types.TOGGLE_TYPE, type);
  },
  toggleCategory({ commit }, category) {
    commit(types.TOGGLE_CATEGORY, category);
    LogService.event('Navigation', 'toggleCategory', category);
  },
  toggleTeam({ commit }, team) {
    LogService.event('Navigation', 'toggleTeam', team);
    commit(types.TOGGLE_TEAM, team);
  },
  toggleTag({ commit }, tag) {
    LogService.event('Navigation', 'toggleTag', tag);
    commit(types.TOGGLE_TAG, tag);
  },
  toggleFilterTag({ commit }, filterTag) {
    LogService.event('Navigation', 'toggleFilterTag', filterTag);
    commit(types.TOGGLE_FILTER_TAG, filterTag);
  },
  setDate({ commit }, date) {
    LogService.event('Navigation', 'setDate', date);
    commit(types.SET_DATE, date);
  },
  setSearchTerm({ commit }, searchTerm) {
    LogService.event('Navigation', 'setSearchTerm', searchTerm);
    commit(types.SET_SEARCH_TERM, searchTerm);
  },
  setIsRecommended({ commit }, recommended) {
    LogService.event('Navigation', 'setIsRecommended', JSON.stringify(recommended));
    commit(types.SET_IS_RECOMMENDED, recommended);
  },
  setSortOrder({ commit }, sortOrder) {
    LogService.event('Navigation', 'setSortOrder', sortOrder);
    commit(types.SET_SORT_ORDER, sortOrder);
  },
  showPreview({ commit, dispatch }, { posting, options }) {
    commit(types.SET_ACTIVE_POSTING, posting);

    if (options && typeof options.flickingIndex === 'number') {
      commit(types.SET_ACTIVE_POSTING_FLICKING_INDEX, options.flickingIndex);
    }

    dispatch('createTrackingLog', {
      Action: LOG_ACTION_PREVIEW,
      PostingID: posting.id,
      EntityID: posting.id,
      Entity: LOG_ENTITY_POSTING,
    });
    LogService.event('Posting', 'preview', posting.type, posting.id);
  },
  closePreview({
    state, commit, getters, dispatch,
  }) {
    const posting = getters.activePosting;

    if (posting) {
      dispatch('createTrackingLog', {
        Action: LOG_ACTION_DISMISS,
        PostingID: posting.id,
        EntityID: posting.id,
        Entity: LOG_ENTITY_POSTING,
      });
      LogService.event('Posting', 'dismiss', posting.type, posting.id);

      commit(types.SET_ACTIVE_POSTING, null);
    }

    if (state.activePostingFlickingIndex !== 0) {
      commit(types.SET_ACTIVE_POSTING_FLICKING_INDEX, 0);
    }
  },
  setActivePosting({ commit }, posting) {
    commit(types.SET_ACTIVE_POSTING, posting);
  },
  setActiveDropDownFilter({ commit }, filter) {
    commit(types.SET_ACTIVE_DROPDOWN_FILTER, filter);
  },
  setUpdateAvailableStatus({ commit }, status) {
    commit(types.SET_UPDATE_AVAILABLE, status);
  },
  setGridLoadingState({ commit }, loadingState) {
    commit(types.SET_GRID_LOADING_STATE, loadingState);
  },
  setGridInitedState({ commit }, inited) {
    commit(types.SET_GRID_INITED_STATE, inited);
  },
  saveSession({ state, getters }) {
    try {
      sessionStorage.setItem('smp-mainPageState', JSON.stringify({
        fetchParams: getters.fetchParams,
        results: getters.results,
        locale: state.currentLocale,
      }));
    } catch (e) {
      // noop, some browsers (e.g. some safari versions if in private mode) prevent session storage access
    }
  },
  loadSession({ state, commit, getters }) {
    try {
      let session = sessionStorage.getItem('smp-mainPageState');

      if (session) {
        session = JSON.parse(session);

        // Break and clear storage if the locale does not match
        if (session.locale !== state.currentLocale) {
          sessionStorage.removeItem('smp-mainPageState');
          return;
        }

        const params = session.fetchParams;

        // Restore multi select filter
        ['categories', 'types', 'teams'].forEach((attr) => {
          if (params[attr]
            && Array.isArray(params[attr])
            && params[attr].length > 0) {
            commit(types[`SET_${attr.toUpperCase()}`], params[attr]);
          }
        });

        // Restore search term
        if (params.term && params.term !== '') {
          commit(types.SET_SEARCH_TERM, params.term);
        }

        // Restore recommended state
        if (params.recommended && params.recommended === true) {
          commit(types.SET_IS_RECOMMENDED, true);
        }

        // Restore date
        if (params.created) {
          commit(types.SET_DATE, params.created);
        }

        // Restore activeTab
        if (params.tab) {
          commit(types.SET_ACTIVE_TAB, params.tab);
        }

        // Restore sort order if necessary (and still a valid option)
        if (params.sortOrder
          && params.sortOrder !== getters.sortOrder) {
          commit(types.SET_SORT_ORDER, params.sortOrder);
        }
      }
    } catch (e) {
      // noop, some browsers (e.g. some safari versions if in private mode) prevent session storage access
    }
  },
  resetFilters({ commit }) {
    ['categories', 'types', 'teams'].forEach((filter) => {
      commit(types[`SET_${filter.toUpperCase()}`], []);
    });

    commit(types.SET_SEARCH_TERM, '');
    commit(types.SET_IS_RECOMMENDED, false);
    commit(types.SET_DATE, null);
    commit(types.SET_SORT_ORDER, 'DESC');
  },
  showMobileFilterMenu({ commit }) {
    commit(types.SET_MOBILE_FILTER_MENU_VISIBILITY, true);
  },
  hideMobileFilterMenu({ commit }) {
    commit(types.SET_MOBILE_FILTER_MENU_VISIBILITY, false);
  },
  toggleMobileFilterMenu({ getters, dispatch }) {
    if (getters.mobileFilterVisible) dispatch('hideMobileFilterMenu');
    else dispatch('showMobileFilterMenu');
  },
  setupViewModeCheck({ commit }) {
    const checkViewMode = debounce(() => {
      commit(types.SET_IS_MOBILE, window.outerWidth < 768);
    }, 400);

    const listener = () => {
      checkViewMode();
    };

    checkViewMode();
    window.addEventListener('resize', listener);
  },
  async init({ dispatch }) {
    dispatch('setupViewModeCheck');
    dispatch('bindCategories');
    dispatch('bindTeams');
    dispatch('bindCupProfile');
    dispatch('loadSession');
  },
  async bindCategories({ commit }) {
    getCollectionObserver('categories', (snapshot) => {
      const docs = snapshot.docs.map((doc) => doc.data());
      commit(types.SET_CATEGORY_LIST, docs);
    });
  },
  async bindTeams({ commit }) {
    getCollectionObserver('teams', (snapshot) => {
      const docs = snapshot.docs.map((doc) => doc.data());
      commit(types.SET_TEAM_LIST, docs);
    });
  },
  async bindCupProfile({ state, commit, dispatch }) {
    getDocumentObserver(`cups/${process.env.VUE_APP_CUP_ID}`, (snapshot) => {
      const cupProfile = snapshot.data();

      if (cupProfile?.creatorTimezone) {
        creatorTimezone$.next(cupProfile.creatorTimezone);
      }

      if (state.cupProfile && state.cupProfile.latestPostingUpdate !== cupProfile.latestPostingUpdate) {
        dispatch('checkForUpdate');
      }

      commit(types.SET_CUP_PROFILE, cupProfile);

      // Active tab is no longer available, fall back to main
      if (cupProfile?.tabs
        && Array.isArray(cupProfile.tabs)
        && !cupProfile.tabs.find((tab) => tab.id === state.activeTab || tab.code === state.activeTab)) {
        commit(types.SET_ACTIVE_TAB, 'main');
      }
    });
  },
  setLocale({ commit }, locale) {
    commit(types.SET_CURRENT_LOCALE, locale);
  },
  setAuthInitialisedState({ commit }, authState) {
    commit(types.SET_AUTH_INITIALISED_STATE, authState);
  },
  setCreatorTimezone({ commit }, timezone) {
    commit(types.SET_CREATOR_TIMEZONE, timezone);
  },
  async checkForUpdate({ commit, getters }) {
    // Cancel if not on home (or "postingPreview", which is the home component with open preview)
    if (!router.currentRoute || !['home', 'postingPreview'].includes(router.currentRoute.name)) {
      return;
    }

    // Wait a second, to ensure that the elastic index is up to date
    // @see https://www.elastic.co/guide/en/elasticsearch/reference/current/near-real-time.html
    setTimeout(async () => {
      const results = await backendService.postings({
        ...getters.fetchParams,
        limit: getters.postings.length + 1, // Fetch one more posting than currently available
      });

      if (!results?.total || results.total === 0) {
        return;
      }

      const prevState = getters.postings.map((p) => parseInt(p.id));
      const newState = results.hits.map((p) => parseInt(p.id));
      const idOfLastPosting = results.hits[results.hits.length - 1].id;

      // Handle deletions
      const toDelete = prevState.filter((id) => !newState.includes(id));
      if (toDelete.length > 0) {
        // TODO disabled for now (also in the update_results mutation)
        // as it would delete postings if the sort order has changed
        // (e.g. sorted by least_downloads, posting is downloaded --> the update fetch would not contain that posting --> deleted)
        // postingsToDelete$.next(toDelete);
      }

      // Update existing postings
      commit(types.UPDATE_RESULTS, {
        postings: results.hits,
        total: results.total,
        aggregations: results.aggregations,
      });

      // Commit new postings only if the update check is enabled
      // New postings are handled within the "Home" component
      if (getters.updateCheckEnabled) {
        /* Do not add existing postings and also not the posting at the end of the new results list,
         as that could be an old one, as we fetch one more record than currently loaded and there may be no new posting
         (so an existing posting was updated)
         */
        const toAdd = newState.filter((id) => !prevState.includes(id) && id !== idOfLastPosting);
        if (toAdd.length > 0) {
          postingsToAdd$.next(results.hits.filter((hit) => toAdd.includes(hit.id)));
        }
      }
    }, 1000);
  },
  addPostings({ commit }, postings) {
    commit(types.ADD_POSTINGS, postings);
  },
  setActiveTab({ commit }, tab) {
    commit(types.SET_ACTIVE_TAB, tab);
  },
  setSupportsNotificationsState({ commit }, supportsNotifications) {
    commit(types.SET_NOTIFICATION_SUPPORTED_STATE, supportsNotifications);
  },
};
