import qs from 'qs';

const getUrlParams = () => qs.parse(location.search, { ignoreQueryPrefix: true, comma: true });

const getUrlParam = (param) => {
  const params = getUrlParams();

  return params && typeof params[param] !== 'undefined' ? params[param] : null;
};

const removeUrlParam = (param) => {
  const params = getUrlParams();
  delete params[param];

  let url;

  // no params left, so remove the whole search part
  if (Object.keys(params).length === 0) {
    url = location.href.replace(location.search, '');
  } else {
    const paramsString = `?${qs.stringify(params, { arrayFormat: 'comma', encode: false })}`;
    url = location.search
      ? location.href.replace(location.search, paramsString)
      : `${location.href}${paramsString}`;
  }

  history.replaceState({}, null, url);
};

export { getUrlParams, getUrlParam, removeUrlParam };
