import Vue from 'vue';
import Vuex from 'vuex';
import { currentLocale$ } from '@/util/i18n';
import { authState$, userTimezone$, creatorTimezone$ } from '@/util/subjects';
import router from '@/router';
import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { getUrlParam } from '@/util/url';
import * as nearLive from './modules/nearLive';
import * as userModule from './modules/user';

Vue.use(Vuex);

const store = new Vuex.Store({
  actions,
  getters,
  mutations,
  state,
  modules: {
    nearLive,
    user: userModule,
  },
});

currentLocale$.subscribe((locale) => {
  store.dispatch('setLocale', locale);
});

authState$.subscribe(async (user) => {
  const requestToken = getUrlParam('token');

  // Remember the token if no user logged in, may be used for auto-login
  if (requestToken && !user) {
    try {
      sessionStorage.setItem('smp-autoLoginToken', requestToken);
      sessionStorage.setItem('smp-loginBackUrl', location.href);
    } catch (e) {
      // noop, some browsers (e.g. some safari versions if in private mode) prevent session storage access
    }
  }

  await store.dispatch('user/setUser', user, { root: true });

  if (user) {
    if (router.currentRoute && router.currentRoute.name === 'login') {
      await router.push({ name: 'home' }).catch(() => {});
    }
  } else {
    await router.push({ name: 'login' }).catch(() => {});
  }

  await store.dispatch('setAuthInitialisedState', true);
});

userTimezone$.subscribe((timezone) => {
  store.dispatch('user/setUserTimezone', timezone, { root: true });
});

creatorTimezone$.subscribe((timezone) => {
  store.dispatch('setCreatorTimezone', timezone);
});

// Immediately dispatch the init action
store.dispatch('init');

export default store;
