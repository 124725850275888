import * as types from './mutation-types';

export default {
  [types.SET_USER](state, user) {
    state.user = user;
  },
  [types.SET_USER_TIMEZONE](state, timezone) {
    state.userTimezone = timezone;
  },
  [types.SET_USER_ID_TOKEN_RESULT](state, idTokenResult) {
    state.userIdTokenResult = idTokenResult;
  },
  [types.SET_USER_PROFILE](state, userProfile) {
    state.userProfile = userProfile;
  },
  [types.SET_USER_PROFILE_UNSUBSCRIBE_FN](state, fn) {
    state.userProfileUnsubscribeFn = fn;
  },
  [types.SET_ACCOUNT_VIEW_VISIBILITY](state, isVisible) {
    state.isAccountViewVisible = isVisible;
  },
  [types.SET_ONBOARDING_VISIBILITY](state, isVisible) {
    state.isOnboardingVisible = isVisible;
  },
};
