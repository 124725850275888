<template>
  <fa-layers v-if="isMultiClip">
    <fa-icon
      :icon="['fas', 'square']"
      transform="shrink-4 up--2 right--4" />

    <fa-icon
      :icon="['fas', 'square']"
      class="fa-inverse"
      transform="shrink-4 right--2" />

    <fa-icon
      :icon="typeIcon"
      transform="shrink-4 grow-1 up-1" />
  </fa-layers>
  <fa-icon
    v-else
    :icon="typeIcon" />
</template>

<script>
import { getIconForPostingType } from '@/util/utils';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    typeIcon() {
      return getIconForPostingType(this.type);
    },
    isMultiClip() {
      return this.type === 'multi_clip';
    },
  },
};
</script>
