<template>
  <div>
    <flicking
      ref="flicking"
      :plugins="plugins"
      :key="`postingPreviewVideo-${posting.id}-${currentLocale}`"
      @transitionstart="stopVideo"
      @changed="flickingChanged">
      <div
        class="smp-flickingPane"
        v-for="(video, i) in videos"
        :key="video.uuid">
        <video-player
          ref="player"
          :key="video.uuid"
          :posting="posting"
          :asset="video"
          :destroyed="destroyed"
          :is-active="activeIndex === i"
          @loaded="videoLoaded(video.uuid)"
          @error="$emit('error', $event)" />
      </div>
      <div
        slot="viewport"
        class="flicking-pagination" />
      <span
        slot="viewport"
        class="flicking-arrow-prev" />
      <span
        slot="viewport"
        class="flicking-arrow-next" />
    </flicking>
  </div>
</template>

<script>
import { Pagination, Arrow } from '@egjs/flicking-plugins';
import { mapState } from 'vuex';
import VideoPlayer from '@/components/VideoPlayer.vue';
import PostingMixin from '@/mixins/Posting';

export default {
  props: {
    destroyed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { VideoPlayer },
  mixins: [PostingMixin],
  data() {
    return {
      plugins: [new Pagination({ type: 'bullet' })],
      videos: [],
      activeIndex: 0,
    };
  },
  created() {
    this.videos = this.videoAssets.map((v) => ({
      ...v,
      loaded: false,
    }));
  },
  computed: {
    ...mapState(['currentLocale']),
    allVideosLoaded() {
      return !this.videos.find((v) => !v.loaded);
    },
  },
  watch: {
    allVideosLoaded() {
      if (this.allVideosLoaded) {
        this.loaded();
      }
    },
  },
  methods: {
    flickingChanged(event) {
      this.activeIndex = event.index;
      setTimeout(() => {
        if (this?.$refs?.player?.[this.activeIndex]) {
          this.$refs.player[this.activeIndex].startVideo();
        }
      }, 250);
    },
    videoLoaded(uuid) {
      this.videos.find((v) => v.uuid === uuid).loaded = true;
    },
    loaded() {
      this.$emit('loaded');

      // Wait for the zoom animation to finish
      setTimeout(() => {
        // Add the arrow plugin only now, as the disabled state will be false if added before
        this.$refs.flicking.addPlugins(new Arrow());
        if (this?.$refs?.player?.[0]) {
          this.$refs.player[0].startVideo();
        }
      }, 250);
    },
    stopVideo() {
      if (this?.$refs?.player?.[this.activeIndex]) {
        this.$refs.player[this.activeIndex].stopVideo();
      }
    },
  },
};
</script>
