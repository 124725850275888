<template>
  <div
    class="smp-team"
    :class="[`smp-team--flag-${flagPosition}`]"
    v-if="isAvailable">
    <span
      v-if="showFlag && flagClass"
      class="smp-tag-icon flag"
      :class="[flagClass]" />
    {{ name }}
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getTeamLabel } from '@/util/i18n';

export default {
  props: {
    code: {
      type: String,
      required: true,
    },
    showFlag: {
      type: Boolean,
      default: true,
    },
    flagPosition: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    ...mapState(['teamList', 'currentLocale']),
    config() {
      return this.teamList.find((t) => t.code === this.code);
    },
    isAvailable() {
      return !!this.config;
    },
    countryCode() {
      return this.config?.countryCode;
    },
    isFifaCode() {
      return this.config?.isFifaCode;
    },
    flagClass() {
      if (this.isAvailable && this.countryCode) {
        return this.isFifaCode ? `flag-fifa-${this.countryCode.toLowerCase()}` : `flag-${this.countryCode.toLowerCase()}`;
      }

      return null;
    },
    name() {
      return getTeamLabel(this.code, this.teamList, this.currentLocale);
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-team {
  display: flex;
  flex-direction: row;

  span.flag {
    margin-right: @space-2;
  }

  &.smp-team--flag-right {
    flex-direction: row-reverse;

    span.flag {
      margin-right: 0;
      margin-left: @space-2;
    }
  }
}
</style>
