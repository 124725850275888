<template>
  <button
    class="smp-button smp-isRecommendedFilter flex items-center"
    :class="{ disabled: !hasResults }"
    @click.prevent="toggle">
    <i class="mr2">
      <fa-icon :icon="icon" />
    </i>
    <span class="ms-1">
      {{ $t('filter.recommended.title') }}
    </span>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isRecommended', 'aggregations']),
    icon() {
      if (this.isRecommended) return ['far', 'check-square'];
      return ['far', 'square'];
    },
    hasResults() {
      return !!this.aggregations?.recommended?.find((bucket) => bucket.key === 1);
    },
  },
  methods: {
    ...mapActions(['setIsRecommended']),
    toggle() {
      this.setIsRecommended(!this.isRecommended);
    },
  },
};
</script>

<style lang="less">
  @import (reference) '~@/styles/base';
  .smp-isRecommendedFilter {
    cursor: pointer;
    .noselect();
    color: @color-primary;
    transition: color 250ms ease-in-out;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      color: lighten(@color-primary, 10);
    }

    i {
      .ms(0);
    }
  }
</style>
