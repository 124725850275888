import * as types from './mutation-types';
import { getDocumentObserver } from '@/util/firebase';
import { notificationsEnabled$ } from '@/util/subjects';
import logger from '@/util/logger';

export default {
  async setUser({ commit, dispatch }, user) {
    commit(types.SET_USER, user);

    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      commit(types.SET_USER_ID_TOKEN_RESULT, idTokenResult);

      // Bind the user profile only for actual frontend "users", silverstripe members do not have one (yet)
      if (idTokenResult?.claims?.user_type === 'user') {
        dispatch('bindUserProfile');
      } else {
        // Just continue with the notification handling for members
        // will lead to a permission request triggered by the getToken method of fcm
        notificationsEnabled$.next(true);
      }
    } else {
      commit(types.SET_USER_ID_TOKEN_RESULT, null);
      dispatch('unbindUserProfile');
    }
  },
  setUserTimezone({ commit }, timezone) {
    commit(types.SET_USER_TIMEZONE, timezone);
  },
  bindUserProfile({ state, commit, dispatch }) {
    // Cancel previous subscriptions
    dispatch('unbindUserProfile');

    const unsubscribeFn = getDocumentObserver(`users/${state.user.uid}`, (snapshot) => {
      const userProfile = snapshot.data();
      commit(types.SET_USER_PROFILE, userProfile);

      if (userProfile) {
        if (!userProfile.hasSeenOnboarding) {
          dispatch('showOnboardingView');
        }
      } else {
        logger.captureMessage(`Could not find user profile for user ${state.user.uid}`);
      }
    });

    commit(types.SET_USER_PROFILE_UNSUBSCRIBE_FN, unsubscribeFn);
  },
  unbindUserProfile({ state, commit }) {
    if (state.userProfileUnsubscribeFn) {
      state.userProfileUnsubscribeFn();
      commit(types.SET_USER_PROFILE_UNSUBSCRIBE_FN, null);
    }
  },
  showAccountView({ commit }) {
    commit(types.SET_ACCOUNT_VIEW_VISIBILITY, true);
  },
  hideAccountView({ commit }) {
    commit(types.SET_ACCOUNT_VIEW_VISIBILITY, false);
  },
  showOnboardingView({ commit }) {
    commit(types.SET_ONBOARDING_VISIBILITY, true);
  },
  hideOnboardingView({ commit }) {
    commit(types.SET_ONBOARDING_VISIBILITY, false);
  },
};
