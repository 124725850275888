import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

import state from '@/store/state';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/p/:id',
    name: 'postingPreview',
    component: Home,
  },
  {
    path: '/near-live',
    name: 'nearLive',
    component: () => import(/* webpackChunkName: "nearLive" */ '@/views/NearLive.vue'),
  },
  {
    path: '/pma-content',
    name: 'pmaContent',
    component: () => import(/* webpackChunkName: "pmaContent" */ '@/views/PmaContent.vue'),
  },
  {
    path: '/draft/:id',
    name: 'draftPage',
    component: () => import(/* webpackChunkName: "draftPage" */ '@/views/DraftPage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (state.authInitialised) {
    if (!state.user.user && to.name !== 'login') {
      next({ name: 'login' });
      return;
    }

    if (state.user.user && to.name === 'login') {
      next({ name: 'home' });
      return;
    }
  }

  next();
});

export default router;
