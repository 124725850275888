<template>
  <ValidationProvider
    :name="validatorName"
    v-slot="{ errors }"
    :rules="rules"
    :mode="mode"
    tag="div"
    class="field">
    <label
      v-if="label"
      :for="name">
      {{ label }} <span v-if="isRequired && showRequiredIndicator">*</span>
      <span
        v-if="errors.length > 0"
        class="smp-validationError">
        {{ errors[0] }}
      </span>
    </label>

    <div
      class="field-inputWrapper relative"
      :class="{ invalid: errors.length > 0 }">
      <input
        v-if="type !== 'textarea'"
        ref="input"
        :id="name"
        :type="activeInputType"
        :name="name"
        :value="value"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        @input="$emit('input', $event)"
        v-on="$listeners">

      <div
        class="field-passwordToggle"
        v-if="type === 'password'"
        @click.prevent="togglePasswordVisibility">
        <div
          key="showPwIcon"
          v-if="activeInputType === 'password'">
          <fa-icon icon="eye" />
        </div>
        <div
          key="HidePwIcon"
          v-if="activeInputType !== 'password'">
          <fa-icon icon="eye-slash" />
        </div>
      </div>

      <textarea
        v-if="type === 'textarea'"
        ref="input"
        :id="name"
        :name="name"
        :value="value"
        :disabled="disabled"
        @input="$emit('input', $event)"
        v-on="$listeners" />
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    validatorName: {
      type: String,
      required: false,
      default() {
        return this.label;
      },
    },

    rules: {
      type: String,
      required: false,
      default: null,
    },

    mode: {
      type: String,
      required: false,
      default: 'eager',
    },

    type: {
      type: String,
      required: false,
      default: 'text',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    showRequiredIndicator: {
      type: Boolean,
      required: false,
      default: true,
    },

    autocomplete: {
      type: String,
      required: false,
      default: '',
    },

    autocapitalize: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      activeInputType: null,
    };
  },
  created() {
    this.activeInputType = this.type;
  },
  computed: {
    isRequired() {
      return this.rules && this.rules.indexOf('required') !== -1;
    },
  },
  methods: {
    togglePasswordVisibility() {
      if (this.activeInputType === 'password') {
        this.activeInputType = 'text';
      } else {
        this.activeInputType = 'password';
      }
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
