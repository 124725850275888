import { mapState, mapGetters, mapActions } from 'vuex';
import moment from '@/util/moment';
import { getIconForPostingType } from '@/util/utils';
import { getCategoryLabel } from '@/util/i18n';

export default {
  props: {
    posting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['categoryList', 'currentLocale', 'userTimezone', 'creatorTimezone', 'teamList']),
    ...mapGetters(['availableTypes', 'defaultLocale']),
    title() {
      const title = this.posting.title[this.currentLocale];

      if (title) {
        return title;
      }

      return this.posting.title[this.defaultLocale];
    },
    category() {
      return getCategoryLabel(this.posting.category, this.categoryList, this.currentLocale);
    },
    downloads() {
      return this.posting.downloads ?? 0;
    },
    publishedNotice() {
      let m = moment(this.posting.dateOfFirstPublication, moment.getDateTimeFormat());
      m = m.toUserTimezone();

      if (this.currentLocale) m.locale(this.currentLocale);

      return m.fromNow();
    },
    typeIcon() {
      return getIconForPostingType(this.posting.type);
    },
    typeLabel() {
      return this.$t(`posting.type.${this.posting.type.toLowerCase()}`);
    },
    hasMultipleAssets() {
      return this.assets.length > 1;
    },
    isClip() {
      return this.posting.type === 'clip' || this.posting.type === 'multi_clip';
    },
    isMultiClip() {
      return this.posting.type === 'multi_clip';
    },
    is360Clip() {
      return this.posting.type === 'clip-360';
    },
    isImage() {
      return this.posting.type === 'image' || this.posting.type === 'interactive_image';
    },
    isInfoGraphic() {
      return this.posting.type === 'info';
    },
    publishedDateInUserTimezone() {
      return this.getPublishedDate();
    },
    publishedDateInCreatorTimezone() {
      const m = this.getPublishedDate(false, false);

      return m.format(moment.getUserDateTimeFormat());
    },
    userIsInCreatorTimezone() {
      return this.userTimezone === this.creatorTimezone;
    },
    imageResolution() {
      const asset = this.primaryAsset;
      if (asset && asset.type === 'image' && asset.width && asset.height) {
        return `${asset.width} x ${asset.height} ${this.$t('posting.resolutionPixel')}`;
      }

      return null;
    },
    teams() {
      // Return only teams whose team data object is still available
      return this.posting.teams
        .filter((teamCode) => !!this.teamList.find((t) => t.code === teamCode));
    },
    assets() {
      let assets = [];

      if (this.isImage || this.isInfoGraphic) {
        assets.push(this.posting.image);
      }

      if (this.posting.assets) {
        // Add all assets that are available for the current locale
        assets = assets.concat(this.posting.assets.filter((asset) => !(asset.locale && asset.locale !== this.currentLocale)));
      }

      // Fallback to assets of the default locale if 0 assets found for the current locale
      if (assets.length === 0 && this.posting.assets) {
        assets = assets.concat(this.posting.assets.filter((asset) => !(asset.locale && asset.locale !== this.defaultLocale)));
      }

      return assets;
    },
    videoAssets() {
      if (this.isClip) {
        return this.assets.filter((asset) => asset.type === 'video');
      }

      return null;
    },
    primaryAsset() {
      let primaryAsset = this.assets.find((asset) => asset.isPrimaryAsset);

      if (primaryAsset) {
        return primaryAsset;
      }

      // Try to fallback to the first video for (360) clips
      if (this.isClip || this.is360Clip) {
        primaryAsset = this.assets.find((asset) => asset.type === 'video');

        if (primaryAsset) {
          return primaryAsset;
        }
      }

      // Try to fallback to the first psd for infographics
      if (this.isInfoGraphic) {
        primaryAsset = this.assets.find((asset) => asset.type === 'psd');

        if (primaryAsset) {
          return primaryAsset;
        }
      }

      // Fallback to the first image asset
      primaryAsset = this.assets.find((asset) => asset.type === 'image');
      if (primaryAsset) {
        return primaryAsset;
      }

      // Fall back to the main image otherwise
      return this.posting.image;
    },
    previewImages() {
      let images = [];

      if (this.posting.image) {
        images.push(this.posting.image);
      }

      if (this.posting.assets && (this.isImage || this.isInfoGraphic)) {
        images = images.concat(this.posting.assets.filter((asset) => {
          if (asset.type !== 'image') {
            return false;
          }

          if (asset.locale && asset.locale !== this.currentLocale) {
            return false;
          }

          return asset.showInPreview;
        }));
      }

      return images;
    },
    postingLink() {
      return `/p/${this.posting.id}`;
    },
  },
  methods: {
    ...mapActions(['showPreview']),
    getPublishedDate(useUserTimezone = true, format = true, short = false) {
      const givenFormat = this.posting.dateOfFirstPublication.length === 10
        ? moment.getDateFormat()
        : moment.getDateTimeFormat();
      let dateObj = moment(this.posting.dateOfFirstPublication, givenFormat);

      if (useUserTimezone === true) dateObj = dateObj.toUserTimezone();
      else dateObj = dateObj.toCreatorTimezone();

      if (!format) return dateObj;

      return dateObj.format(moment.getUserDateTimeFormat(short));
    },
    openPreview() {
      if (!this.primaryAsset) {
        this.$notification.warning(this.$t('draftPage.missingPrimaryAsset'));
        return;
      }

      if ((this.isClip || this.is360Clip) && this.primaryAsset.type !== 'video') {
        this.$notification.warning(this.$t('draftPage.missingVideoAsset'));
        return;
      }

      const options = {};

      if (this.$refs.flicking) {
        options.flickingIndex = this.$refs.flicking.index;
      }

      this.showPreview({ posting: this.posting, options });
    },
  },
  created() {
    if (this.posting.locale) window.forceLocale = this.posting.locale;
  },
  mounted() {
    window.forceLocale = null;
  },
  beforeUpdate() {
    if (this.posting.locale) window.forceLocale = this.posting.locale;
  },
  updated() {
    window.forceLocale = null;
  },
};
