<template>
  <div
    class="smp-tab"
    :class="{'smp-tab--active': isActive}"
    @click.prevent="select">
    {{ title }}
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  props: {
    tab: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentLocale', 'activeTab']),
    ...mapGetters(['defaultLocale']),
    title() {
      const title = this.tab.title[this.currentLocale];

      if (title) {
        return title;
      }

      return this.tab.title[this.defaultLocale];
    },
    isActive() {
      if (['nearLive', 'pmaContent'].includes(this.$route.name)) {
        return this.tab.code === this.$route.name;
      }

      if (this.tab.code && this.tab.code === this.activeTab) {
        return true;
      }

      return this.tab.id === this.activeTab;
    },
  },
  methods: {
    ...mapActions(['setActiveTab']),
    async select() {
      if (this.tab.code && ['nearLive', 'pmaContent'].includes(this.tab.code)) {
        if (this.$route.name !== this.tab.code) {
          await this.$router.push({ name: this.tab.code });
        }
      } else {
        await this.setActiveTab(this.tab.id);

        if (this.$route.name !== 'home') {
          await this.$router.push({ name: 'home' });
        }
      }
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/vars";

.smp-tab {
  padding: @space-2 @space-3;
  background: @color-mono-92;
  position: relative;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0 @space-1 / 2;
  min-width: 120px;

  &, a {
    color: @color-mono-20;
  }

  &.smp-tab--active {
    background: @color-mono-70;
    position: relative;
    color: @color-mono-0;
    font-weight: bold;
  }

  &:hover {
    background: @color-mono-70;
  }
}
</style>
