<template>
  <div
    class="smp-userMenu"
    :class="{'is-open': isOpen }">
    <div
      class="smp-button smp-button-primary"
      @click="toggle">
      <fa-icon
        class="mr1"
        icon="user" />
    </div>

    <div
      class="smp-userMenu-content"
      v-show="isOpen">
      <nav>
        <ul>
          <li
            v-if="isMember"
            class="smp-userMenu-option">
            <a
              :href="memberUserProfileLink"
              target="_blank">
              <span class="smp-userMenu-optionIcon">
                <fa-icon icon="user-cog" />
              </span>
              {{ $t('userMenu.settings') }}
            </a>
          </li>
          <li
            v-if="!isMember"
            class="smp-userMenu-option">
            <a
              href=""
              @click.prevent="showAccountView">
              <span class="smp-userMenu-optionIcon">
                <fa-icon icon="user-cog" />
              </span>
              {{ $t('userMenu.settings') }}
            </a>
          </li>
          <li class="smp-userMenu-option">
            <a
              href=""
              @click.prevent="logout">
              <span class="smp-userMenu-optionIcon">
                <fa-icon :icon="['fas', 'sign-out-alt']" />
              </span>
              {{ $t('userMenu.logout') }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DropDownMixin from '@/mixins/DropDown';
import { logout } from '@/util/firebase';

export default {
  data() {
    return {
      containerClass: 'smp-userMenu',
    };
  },
  mixins: [DropDownMixin],
  computed: {
    ...mapGetters('user', ['isMember']),
    memberUserProfileLink() {
      return `${process.env.VUE_APP_BACKEND_URL}/admin/myprofile`;
    },
  },
  methods: {
    ...mapActions('user', ['showAccountView']),
    async logout() {
      await logout();
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";
.smp-userMenu {
  position: relative;
  .noselect();

  .smp-userMenu-content {
    position: absolute;
    z-index: 90;
    bottom: -@space-1;
    right: 0;
    transform: translateY(100%);
    background: @color-primary;
    color: @color-mono-100;
    border-radius: @defaultBorderRadius;
    min-width: 100%;

    nav ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .smp-userMenu-option {
      white-space: nowrap;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: @space-3;
      transition: all 250ms ease-in-out;

      a {
        color: inherit;
        text-decoration: none;
      }

      .smp-userMenu-optionIcon {
        width: 30px;
        display: inline-block;
      }

      &:hover {
        background: lighten(@color-primary, 10);
      }

      &:not(:last-child) {
        border-bottom: 2px solid lighten(@color-primary, 10);
      }
    }
  }
}
</style>
