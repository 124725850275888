<template>
  <span>
    <slot />
  </span>
</template>

<script>
import Tooltip from 'tooltip.js';

export default {
  data() {
    return {
      tooltip: null,
    };
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: 'top',
    },
    delay: {
      type: [Number, Object],
      required: false,
      default: 0,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const options = {
        trigger: 'hover',
        html: true,
        title: this.content,
        placement: this.placement,
        closeOnClickOutside: true,
        delay: this.delay,
      };

      this.tooltip = new Tooltip(this.$el, options);
    },
  },
};
</script>

<style lang="less">
  @import (reference) '~@/styles/base';
  @backgroundColor: #333;

  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    word-wrap: break-word;
    .ms(-1);

    .tooltip-arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem;

      &::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
      }
    }

    .tooltip-inner {
      max-width: 200px;
      padding: 0.25rem 0.5rem;
      color: @color-mono-100;
      text-align: center;
      background-color: @backgroundColor;
      border-radius: 0.25rem;
    }
  }

  .tooltip[x-placement^="top"] {
    padding: 0.4rem 0;

    .tooltip-arrow {
      bottom: 0;

      &::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: @backgroundColor;
      }
    }
  }

  .tooltip[x-placement^="right"] {
    padding: 0 0.4rem;

    .tooltip-arrow {
      left: 0;
      width: 0.4rem;
      height: 0.8rem;

      &::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: @backgroundColor;
      }
    }
  }

  .tooltip[x-placement^="bottom"] {
    padding: 0.4rem 0;

    .tooltip-arrow {
      top: 0;

      &::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: @backgroundColor;
      }
    }
  }

  .tooltip[x-placement^="left"] {
    padding: 0 0.4rem;

    .tooltip-arrow {
      right: 0;
      width: 0.4rem;
      height: 0.8rem;

      &::before {
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: @backgroundColor;
      }
    }
  }

  .tooltip-content-slot {
    display: none;
  }

  .tooltip .tooltip-content-slot {
    display: block;
  }
</style>
