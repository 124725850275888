/* eslint-disable no-param-reassign */
import Vue from 'vue';
import * as types from './mutation-types';

export default {
  [types.SET_RESULTS](state, results) {
    state.results = results;
  },
  [types.UPDATE_RESULTS](state, results) {
    state.results.aggregations = results.aggregations;
    state.results.total = results.total;

    // Update existings hits
    results.postings.forEach((hit) => {
      const index = state.results.postings.findIndex((p) => parseInt(p.id) === parseInt(hit.id));

      if (index > -1) {
        Vue.set(state.results.postings, index, hit);
      }
    });

    // Delete postings that are no longer available
    /* const newResultIds = results.postings.map((hit) => parseInt(hit.id));
    state.results.postings.forEach((posting) => {
      if (!newResultIds.includes(parseInt(posting.id))) {
        const index = state.results.postings.findIndex((p) => parseInt(p.id) === parseInt(posting.id));

        if (index > -1) {
          state.results.postings.splice(index, 1);
        }
      }
    }); */
  },
  [types.ADD_POSTINGS](state, postings) {
    state.results.postings = state.results.postings.concat(postings);
  },
  [types.SET_AGGREGATIONS](state, aggregations) {
    state.results.aggregations = aggregations;
  },
  [types.TOGGLE_TYPE](state, type) {
    if (state.types.indexOf(type) > -1) {
      state.types.splice(state.types.indexOf(type), 1);
    } else {
      state.types.push(type);
    }
  },
  [types.TOGGLE_CATEGORY](state, category) {
    if (state.categories.indexOf(category) > -1) {
      state.categories.splice(state.categories.indexOf(category), 1);
    } else {
      state.categories.push(category);
    }
  },
  [types.TOGGLE_TEAM](state, team) {
    if (state.teams.indexOf(team) > -1) {
      state.teams.splice(state.teams.indexOf(team), 1);
    } else {
      state.teams.push(team);
    }
  },
  [types.TOGGLE_TAG](state, tag) {
    if (state.activeTags.indexOf(tag) > -1) {
      state.activeTags.splice(state.activeTags.indexOf(tag), 1);
    } else {
      state.activeTags.push(tag);
    }
  },
  [types.TOGGLE_FILTER_TAG](state, filterTag) {
    if (state.activeFilterTags.indexOf(filterTag) > -1) {
      state.activeFilterTags.splice(state.activeFilterTags.indexOf(filterTag), 1);
    } else {
      state.activeFilterTags.push(filterTag);
    }
  },
  [types.SET_TYPES](state, postingTypes) {
    state.types = postingTypes;
  },
  [types.SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [types.SET_ACTIVE_TAB](state, tab) {
    state.activeTab = tab;
  },
  [types.SET_TEAMS](state, teams) {
    state.teams = teams;
  },
  [types.SET_DATE](state, date) {
    state.date = date;
  },
  [types.SET_SEARCH_TERM](state, searchTerm) {
    state.searchTerm = searchTerm;
  },
  [types.SET_IS_RECOMMENDED](state, recommended) {
    state.recommended = recommended;
  },
  [types.SET_SORT_ORDER](state, sortOrder) {
    state.sortOrder = sortOrder;
  },
  [types.LOCK](state) {
    state.locked = true;
  },
  [types.UNLOCK](state) {
    state.locked = false;
  },
  [types.SET_ACTIVE_POSTING](state, posting) {
    state.activePosting = posting;
  },
  [types.SET_ACTIVE_POSTING_FLICKING_INDEX](state, index) {
    state.activePostingFlickingIndex = index;
  },
  [types.SET_ACTIVE_DROPDOWN_FILTER](state, filter) {
    state.activeDropDownFilter = filter;
  },
  [types.SET_UPDATE_AVAILABLE](state, available) {
    state.updateAvailable = available;
  },
  [types.SET_GRID_LOADING_STATE](state, loading) {
    state.gridLoading = loading;
  },
  [types.SET_GRID_INITED_STATE](state, inited) {
    state.gridInited = inited;
  },
  [types.SET_MOBILE_FILTER_MENU_VISIBILITY](state, visible) {
    state.mobileFilterMenuVisible = visible;
  },
  [types.SET_IS_MOBILE](state, isMobile) {
    state.isMobile = isMobile;
  },
  [types.SET_CATEGORY_LIST](state, categoryList) {
    state.categoryList = categoryList;
  },
  [types.SET_TEAM_LIST](state, teamList) {
    state.teamList = teamList;
  },
  [types.SET_CUP_PROFILE](state, cupProfile) {
    state.cupProfile = cupProfile;
  },
  [types.SET_CURRENT_LOCALE](state, locale) {
    state.currentLocale = locale;
  },
  [types.SET_AUTH_INITIALISED_STATE](state, authState) {
    state.authInitialised = authState;
  },
  [types.SET_CREATOR_TIMEZONE](state, timezone) {
    state.creatorTimezone = timezone;
  },
  [types.SET_NOTIFICATION_SUPPORTED_STATE](state, supportsNotifications) {
    state.supportsNotifications = supportsNotifications;
  },
};
