<template>
  <div class="smp-card smp-postingCard">
    <posting-card-image
      :posting="posting" />

    <div class="smp-postingCard-type">
      <tooltip
        :content="typeLabel"
        :delay="{ show: 500, hide: 0 }">
        <posting-type-icon :type="posting.type" />
      </tooltip>
    </div>

    <div
      class="smp-postingCard-recommended"
      v-if="posting.recommended">
      {{ $t('posting.recommended') }}
    </div>

    <div class="smp-postingCard-main">
      <div class="smp-postingCard-details">
        <div class="flex items-baseline ms-1">
          <div
            class="smp-postingCard-category mr1"
            v-if="posting.category && showCategoryInfo">
            {{ category }}
          </div>
          <div
            v-if="showDownloadCounts"
            class="text-muted ms-2">
            ({{ downloads }} {{ $t('posting.downloads') }})
          </div>
        </div>
        <div class="smp-postingCard-title ms1 my1 font-normal font-highlight">
          {{ title }}
        </div>
        <div class="smp-postingCard-date ms-1 bold">
          <tooltip
            :content="getPublishedDate(true, true, false)"
            :delay="{ show: 500, hide: 0 }">
            {{ publishedNotice }}
          </tooltip>
        </div>
      </div>

      <div class="smp-postingCard-footer mt2">
        <div
          class="smp-postingCard-creatorTime text-muted ms-1 flex-auto"
          v-if="!userIsInCreatorTimezone">
          <strong>{{ creatorTimezoneLabel }}</strong><br>
          {{ getPublishedDate(false, true, true) }}
        </div>

        <div class="smp-postingCard-downloadCta flex-none">
          <download-button
            :posting="posting"
            button-classes="smp-button smp-button-light" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DownloadButton from '@/components/DownloadButton.vue';
import Posting from '@/mixins/Posting';
import Tooltip from '@/components/Tooltip.vue';
import PostingTypeIcon from '@/components/PostingTypeIcon.vue';
import PostingCardImage from '@/components/PostingCardImage.vue';

export default {
  components: {
    DownloadButton, Tooltip, PostingCardImage, PostingTypeIcon,
  },
  mixins: [Posting],
  computed: {
    ...mapGetters(['creatorTimezoneLabel', 'showDownloadCounts', 'showCategoryInfo']),
  },
};
</script>

<style lang="less">
@import "~@/styles/card";

.smp-postingCard {
  display: block;
  position: relative;
  margin-bottom: @gridSpace;
  width: 100%;

  @media screen and (min-width: @breakpoint-xs) {
    width: calc(50%~'-'@gridSpace / 2);
  }

  @media screen and (min-width: @breakpoint-sm) {
    width: calc(33.3333%~'-'@gridSpace * 2 / 3);
  }

  @media screen and (min-width: @breakpoint-lg) {
    width: calc(25%~'-'@gridSpace * 3 / 4);
  }

  @media screen and (min-width: @breakpoint-xl) {
    width: calc(20%~'-'@gridSpace * 4 / 5);
  }

  @media screen and (min-width: @containerLarge) {
    width: calc(16.6666%~'-'@gridSpace * 5 / 6);
  }

  .smp-postingCard-type {
    position: absolute;
    z-index: 10;
    top: @space-2;
    left: @space-2;
    width: 50px;
    height: 50px;
    background: @color-text-for-primary;
    color: @color-primary;
    border: 1px solid @color-secondary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .tooltip {
      white-space: nowrap;
    }

    svg {
      .ms(2);
    }

    > span, i, svg {
      display: inherit;
    }
  }

  .smp-postingCard-recommended {
    position: absolute;
    top: 0;
    right: 0;
    padding: @space-1 @space-2;
    background: @color-primary;
    color: @color-text-for-primary;
    border-bottom-left-radius: @defaultBorderRadius;
    border-top-right-radius: @defaultBorderRadius;
    z-index: 100;
  }

  .smp-postingCard-main {
    padding: @space-3;
  }

  .smp-postingCard-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
</style>
