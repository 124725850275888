export const SET_RESULTS = 'SET_RESULTS';
export const UPDATE_RESULTS = 'UPDATE_RESULTS';
export const SET_AGGREGATIONS = 'SET_AGGREGATIONS';
export const TOGGLE_TYPE = 'TOGGLE_TYPE';
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
export const TOGGLE_TEAM = 'TOGGLE_TEAM';
export const TOGGLE_TAG = 'TOGGLE_TAG';
export const TOGGLE_FILTER_TAG = 'TOGGLE_FILTER_TAG';
export const SET_TYPES = 'SET_TYPES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_DATE = 'SET_DATE';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_IS_RECOMMENDED = 'SET_IS_RECOMMENDED';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';
export const LOCK = 'LOCK_FETCH';
export const UNLOCK = 'UNLOCK_FETCH';
export const ADD_POSTINGS = 'ADD_POSTINGS';
export const SET_ACTIVE_POSTING = 'SET_ACTIVE_POSTING';
export const SET_ACTIVE_DROPDOWN_FILTER = 'SET_ACTIVE_DROPDOWN_FILTER';
export const SET_UPDATE_AVAILABLE = 'SET_UPDATE_AVAILABLE';
export const SET_GRID_LOADING_STATE = 'SET_GRID_LOADING_STATE';
export const SET_GRID_INITED_STATE = 'SET_GRID_INITED_STATE';
export const SET_MOBILE_FILTER_MENU_VISIBILITY = 'SET_MOBILE_FILTER_MENU_VISIBILITY';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST';
export const SET_TEAM_LIST = 'SET_TEAM_LIST';
export const SET_CUP_PROFILE = 'SET_CUP_PROFILE';
export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';
export const SET_AUTH_INITIALISED_STATE = 'SET_AUTH_INITIALISED_STATE';
export const SET_CREATOR_TIMEZONE = 'SET_CREATOR_TIMEZONE';
export const SET_ACTIVE_POSTING_FLICKING_INDEX = 'SET_ACTIVE_POSTING_FLICKING_INDEX';
export const SET_NOTIFICATION_SUPPORTED_STATE = 'SET_NOTIFICATION_SUPPORTED_STATE';
