import * as types from './mutation-types';

export default {
  [types.SET_POSTINGS](state, postings) {
    state.postings = postings;
  },
  [types.SET_MATCH](state, match) {
    state.match = match;
  },
  [types.SET_POSTING_UNSUBSCRIBE_FN](state, fn) {
    state.postingUnsubscribeFn = fn;
  },
  [types.SET_ACTIVE_POSTING](state, posting) {
    state.activePosting = posting;
  },
  [types.SET_VIEW_MODE](state, viewMode) {
    state.viewMode = viewMode;
  },
};
