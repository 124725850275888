<template>
  <div
    class="smp-postingCard-image"
    :class="[
      `smp-postingCard-image--${mode}`,
      {'smp-postingCard-image--flicking': previewImages.length > 1},
      {'smp-postingCard-image--single': previewImages.length === 1}
    ]"
    v-if="hasPreviewImages">
    <flicking
      v-if="previewImages.length > 1"
      ref="flicking"
      :key="`postingCardImage-${posting.id}-${currentLocale}`"
      :plugins="plugins">
      <div
        v-for="image in previewImages"
        :key="image.id"
        class="smp-flickingPane"
        @click="openPreview()">
        <img :src="image.previewUrl ? image.previewUrl : image.url">
      </div>
      <div
        slot="viewport"
        class="flicking-pagination" />
    </flicking>

    <img
      v-if="previewImages.length === 1"
      @click="openPreview()"
      :src="mainPreviewImageSrc">

    <div
      v-if="inPortraitMode"
      class="smp-postingCard-image-blurredBackground"
      :style="{'background-image': `url(${mainPreviewImageSrc})`}" />
  </div>

  <div
    v-else
    class="smp-postingCard-image--fallback"
    @click="openPreview()">
    <fa-icon icon="image" />
  </div>
</template>

<script>
import { Pagination } from '@egjs/flicking-plugins';
import ImagesLoaded from 'imagesloaded';
import Posting from '@/mixins/Posting';

export default {
  data() {
    return {
      plugins: [new Pagination({ type: 'bullet' })],
      mode: 'landscape',
    };
  },
  mixins: [Posting],
  mounted() {
    if (this.hasPreviewImages) {
      const mainImage = this.mainPreviewImage;

      if (mainImage.height && mainImage.width && mainImage.height > mainImage.width) {
        this.mode = 'portrait';
      }

      ImagesLoaded(this.$el, () => {
        if (this.$refs.flicking) {
          this.$refs.flicking.resize();
        }
      });
    }
  },
  computed: {
    hasPreviewImages() {
      return this.previewImages && Array.isArray(this.previewImages) && this.previewImages.length > 0;
    },
    mainPreviewImage() {
      return this.hasPreviewImages ? this.previewImages[0] : null;
    },
    mainPreviewImageSrc() {
      return this.mainPreviewImage.previewUrl ? this.mainPreviewImage.previewUrl : this.mainPreviewImage.url;
    },
    inPortraitMode() {
      return this.mode === 'portrait';
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-postingCard-image {
  border-top-left-radius: @defaultBorderRadius;
  border-top-right-radius: @defaultBorderRadius;
  overflow: hidden;
  cursor: pointer;

  &.smp-postingCard-image--landscape {
    .flicking-viewport {
      .flicking-camera {
        .smp-flickingPane {
          background: @color-mono-0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &.smp-postingCard-image--portrait {
    overflow: hidden;
    cursor: pointer;
    position: relative;
    .flex-none;
    border-top-left-radius: @defaultBorderRadius;
    border-top-right-radius: @defaultBorderRadius;
    width: 100%;
    height: 0;
    padding-bottom: 75%;

    &.smp-postingCard-image--single {
      img {
        position: absolute;
        z-index: 10;
        max-width: 100%;
        max-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.smp-postingCard-image--flicking {
      .flicking-viewport {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        .flicking-camera {
          .smp-flickingPane {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              display: block;
              max-width: 100%;
              max-height: 100%;
              flex-basis: 0;
            }
          }
        }
      }
    }

    .smp-postingCard-image-blurredBackground {
      position: absolute;
      top: -10px;
      left: -10px;
      width: ~'calc(100% + 20px)';
      height: ~'calc(100% + 20px)';
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      .blur(3px);
      opacity: 0.6;
      z-index: 1;
    }
  }
}

.smp-postingCard-image--fallback {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background: @color-mono-90;
  position: relative;
  color: @color-primary;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .ms(5);
  }
}
</style>
