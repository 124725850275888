<template>
  <div>
    <div
      v-show="gridInited"
      ref="grid"
      class="smp-postingGrid">
      <div class="gutter-sizer" />

      <posting-card
        v-for="posting in postings"
        :key="posting.id"
        :posting="posting" />
    </div>

    <infinite-loading
      v-show="!gridLoading && canLoadMore"
      v-cloak
      :distance="300"
      ref="infiniteLoading"
      :identifier="infiniteLoadingIdentifier"
      @infinite="infiniteHandler">
      <!-- Reset all slots -->
      <span slot="no-results" />
      <span slot="no-more" />
      <div
        class="infinite-loader"
        slot="spinner">
        <span>
          <fa-icon
            :icon="['fas', 'spinner']"
            spin />
        </span>
      </div>
    </infinite-loading>

    <div
      class="app--loading"
      v-if="!gridInited">
      <fa-icon
        icon="spinner"
        spin />
    </div>
  </div>
</template>

<script>
import ImagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';
import InfiniteLoading from 'vue-infinite-loading';
import { mapState, mapGetters, mapActions } from 'vuex';
import PostingCard from '@/components/PostingCard.vue';
import LogService from '@/services/LogService';
import { backendService } from '@/services/BackendService';
import { gridFetchRequired$, postingsToDelete$ } from '@/util/subjects';
import { currentLocale$ } from '@/util/i18n';
import { logout } from '@/util/firebase';

export default {
  components: { PostingCard, InfiniteLoading },
  data() {
    return {
      grid: null,
      infiniteLoadingIdentifier: new Date(),
      postingsToDeleteSubscription: null,
      currentLocaleSubscription: null,
      gridRefreshSubscription: null,
    };
  },
  computed: {
    ...mapState(['loadMoreLimit', 'gridInited']),
    ...mapGetters([
      'postings',
      'isLocked',
      'gridLoading',
      'fetchParams',
      'activePosting',
      'canLoadMore',
    ]),
  },
  created() {
    if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
      LogService.init(process.env.VUE_APP_GOOGLE_ANALYTICS_ID);
    }
  },
  mounted() {
    this.init();
    this.loadMoreFromActivePosting(this.activePosting);

    this.postingsToDeleteSubscription = postingsToDelete$.subscribe((ids) => {
      if (ids) {
        this.removePostings(ids);
      }
    });

    this.currentLocaleSubscription = currentLocale$.subscribe(() => {
      if (this.grid) {
        this.$nextTick(() => {
          ImagesLoaded(this.$refs.grid, () => {
            this.grid.layout();
          });
        });
      }
    });

    this.gridRefreshSubscription = gridFetchRequired$.subscribe((val) => {
      if (val) {
        this.refetch();
      }
    });
  },
  beforeDestroy() {
    if (this.postingsToDeleteSubscription) {
      this.postingsToDeleteSubscription.unsubscribe();
    }
    if (this.currentLocaleSubscription) {
      this.currentLocaleSubscription.unsubscribe();
    }
    if (this.gridRefreshSubscription) {
      this.gridRefreshSubscription.unsubscribe();
    }
    this.setGridInitedState(false);
  },
  watch: {
    fetchParams() {
      gridFetchRequired$.next(true);
    },
    activePosting(activePosting) {
      this.loadMoreFromActivePosting(activePosting);
    },
  },
  methods: {
    ...mapActions([
      'fetchPostings',
      'loadMore',
      'lock',
      'unlock',
      'setUpdateAvailableStatus',
      'setGridLoadingState',
      'setGridInitedState',
      'showPreview',
    ]),
    async refetch() {
      if (!this.isLocked) {
        this.setGridLoadingState(true);
        this.setUpdateAvailableStatus(false);

        this.fetchPostings({
          fetchParams: this.fetchParams,
          callback: (postings) => {
            if (postings.length > 0) {
              this.$nextTick(() => {
                // Read new items
                this.grid.reloadItems();
                this.grid.layout();

                // re-layout after all images have loaded
                ImagesLoaded(this.$refs.grid, () => {
                  this.setGridLoadingState(false);

                  this.$nextTick(() => {
                    this.grid.layout();
                    this.$emit('scroll-top');
                  });
                });

                this.resetInfiniteLoading();
              });
            } else {
              this.setGridLoadingState(false);
            }
          },
          errCallback: (response) => {
            let message = response?.data?.message ?? 'generic_error';

            const messageKey = `generic.serverMessages.${message}`;
            if (this.$t(messageKey) !== messageKey) {
              message = this.$t(messageKey);
            }

            this.$notification.error(message);
          },
        });
      } else {
        this.unlock();
      }
    },
    async init() {
      await this.fetchPostings({
        fetchParams: {
          ...this.fetchParams,
        },
        reset: true,
        errCallback: (error) => {
          let message = error?.data?.message ?? 'generic_error';

          const messageKey = `generic.serverMessages.${message}`;
          if (this.$t(messageKey) !== messageKey) {
            message = this.$t(messageKey);
          }

          if (error?.data?.message === 'user_deactivated') {
            logout();
          }

          this.$notification.error(message);
        },
      });

      this.initGrid();

      await this.checkForPreview();
    },
    initGrid() {
      ImagesLoaded(this.$refs.grid, () => {
        this.$nextTick(() => {
          this.setGridLoadingState(false);
          this.setGridInitedState(true);

          this.$nextTick(() => {
            this.grid = new Masonry(
              this.$refs.grid, {
                itemSelector: '.smp-postingCard',
                fitWidth: false,
                gutter: '.gutter-sizer',
                horizontalOrder: true,
                hiddenStyle: {
                  transform: 'translateY(100px)',
                  opacity: 0,
                },
                visibleStyle: {
                  transform: 'translateY(0px)',
                  opacity: 1,
                },
              },
            );
          });
        });
      });
    },
    infiniteHandler($state) {
      this.loadMore({
        fetchParams: {
          ...this.fetchParams,
        },
        reset: false,
        callback: (postings) => {
          if (postings.length > 0) {
            this.layoutNewPostings(postings).then(() => {
              $state.loaded();
            });
          }

          if (!this.canLoadMore) {
            $state.complete();
            LogService.event('Navigation', 'infiniteLoadedComplete', `Length: ${this.postings?.length}`);
          }
        },
        errCallback: (error) => {
          let message = error?.data?.message ?? 'generic_error';

          const messageKey = `generic.serverMessages.${message}`;
          if (this.$t(messageKey) !== messageKey) {
            message = this.$t(messageKey);
          }

          this.$notification.error(message);
        },
      });
    },
    removePostings(ids) {
      const elements = [];
      this.$children.forEach((comp) => {
        if (comp.$options._componentTag === 'posting-card'
          && ids.indexOf(comp.posting.id) > -1) {
          elements.push(comp.$el);
          // eslint-disable-next-line no-param-reassign
          comp.$el.style.display = 'none';
        }
      });

      this.grid.remove(elements);
      this.grid.layout();
    },
    layoutNewPostings(postings, prepend) {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          const ids = postings.map((p) => p.id);

          // Get all newly added dom elements
          const elements = [];
          this.$children.forEach((comp) => {
            if (comp.$options._componentTag === 'posting-card'
              && ids.indexOf(comp.posting.id) > -1) {
              elements.push(comp.$el);
              // eslint-disable-next-line no-param-reassign
              comp.$el.style.display = 'none';
            }
          });

          // Wait until all images have loaded, then inform the masonry grid about the new items
          ImagesLoaded(elements, async () => {
            elements.forEach((element) => {
              // eslint-disable-next-line no-param-reassign
              element.style.display = 'block';
            });

            // Wait until the grid is initialised if not ready yet
            if (!this.grid) {
              const waitForGrid = new Promise((resolve) => {
                const interval = setInterval(() => {
                  if (this.grid) {
                    clearInterval(interval);
                    resolve();
                  }
                }, 1000);
              });

              await waitForGrid;
            }

            if (prepend) {
              this.grid.prepended(elements);
            } else {
              this.grid.appended(elements);
            }

            resolve(elements);
          });
        });
      });
    },
    resetInfiniteLoading() {
      this.infiniteLoadingIdentifier = new Date();
    },

    /**
     * Load more if the activePosting is the last one loaded.
     *
     * @param activePosting
     */
    loadMoreFromActivePosting(activePosting) {
      if (activePosting
        && this.canLoadMore
        && this.postings.findIndex((p) => p.id === activePosting.id) === this.postings.length - 1) {
        this.loadMore({
          fetchParams: {
            ...this.fetchParams,
          },
          reset: false,
          callback: (postings) => {
            if (postings.length > 0) {
              this.layoutNewPostings(postings);
            }
          },
        });
      }
    },
    async checkForPreview() {
      if (this.$route.name === 'postingPreview') {
        const postingId = parseInt(this.$route.params.id);

        let posting = null;
        if (this.postings && Array.isArray(this.postings) && this.postings.length > 0) {
          posting = this.postings.find((p) => p.id === postingId);
        }

        // Posting is not in the current result set, try to fetch it
        if (!posting) {
          posting = await backendService.posting(postingId).catch(() => {});
        }

        // Show the preview, if the posting could be found
        if (posting) {
          this.showPreview({ posting });
        }
      }
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/vars";

.smp-postingGrid {
  .gutter-sizer {
    width: @gridSpace;
  }
}
</style>
