import axios from 'axios';
import { user$ } from '@/util/subjects';
import { getSignedDownloadUrl, auth } from '@/util/firebase';

export const LOG_ACTION_PREVIEW = 'Preview';
export const LOG_ACTION_DISMISS = 'Dismiss';
export const LOG_ACTION_DOWNLOAD = 'Download';
export const LOG_ENTITY_POSTING = 'Posting';
export const LOG_ENTITY_PMA = 'PMA';

/**
 * SDK for handling Backend Services
 */
class BackendService {
  /**
   * Creates axios instance with base URL for API endpoint
   */
  constructor() {
    this.baseEndpoint = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND_URL}/api/v1`,
    });

    // retry logic for failed "invalid_or_missing_user" requests after force refreshing the token
    // partially taken from https://javascript.plainenglish.io/how-to-retry-requests-using-axios-64c2da8340a7
    this.baseEndpoint.interceptors.response.use(undefined, (err) => {
      if (err?.response?.data?.message === 'invalid_or_missing_user') {
        if (auth?.currentUser) {
          const { config } = err;

          if (!config.authRefreshed) {
            return new Promise((resolve) => {
              auth.currentUser
                .getIdTokenResult(true)
                .then((idTokenResult) => {
                  config.authRefreshed = true;
                  config.headers.authorization = `JWT ${idTokenResult.token}`;
                  resolve(axios(config));
                });
            });
          }
        }
      }

      return Promise.reject(err);
    });
  }

  /**
   * Fetches posting records
   * @param params
   * @returns {Promise<*>}
   */
  async postings(params) {
    try {
      const response = await this.baseEndpoint.post(
        '/posting/load',
        {},
        {
          params,
          headers: {
            'smi-cup': process.env.VUE_APP_CUP_ID,
            authorization: `JWT ${user$.value.accessToken}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  /**
   * Fetch a single posting by id.
   * @param id
   * @return {Promise<unknown>}
   */
  async posting(id) {
    try {
      const response = await this.baseEndpoint.get(
        `/posting/${id}`,
        {
          headers: {
            'smi-cup': process.env.VUE_APP_CUP_ID,
            authorization: `JWT ${user$.value.accessToken}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  /**
   * Download the given asset.
   *
   * @return {Promise<*>}
   */
  async download(assetId) {
    return this.getSignedUrl(assetId, true);
  }

  /**
   * Get a signed url for this posting without initiating the download.
   *
   * @param assetId
   * @param directDownload
   * @return {Promise<*>}
   */
  async getSignedUrl(assetId, directDownload = false) {
    try {
      const params = {
        assetId,
        download: directDownload,
      };
      const response = await this.baseEndpoint.get(
        '/posting/getSignedUrl',
        {
          params,
          headers: {
            'smi-cup': process.env.VUE_APP_CUP_ID,
            authorization: `JWT ${user$.value.accessToken}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  /**
   * Sends a tracking log request.
   * @param data
   */
  async log(data) {
    try {
      await this.baseEndpoint.post(
        '/log',
        data,
        {
          headers: {
            'smi-cup': process.env.VUE_APP_CUP_ID,
            authorization: `JWT ${user$.value.accessToken}`,
          },
        },
      );
    } catch (error) {
      throw error.response;
    }
  }

  /**
   *
   * @param email
   * @param password
   * @return {Promise<{ token, uid, claims }>}
   */
  async login(email, password) {
    try {
      const response = await this.baseEndpoint.post(
        '/user/login',
        {
          email,
          password,
        },
        {
          headers: {
            'smi-cup': process.env.VUE_APP_CUP_ID,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async updateUser(data) {
    try {
      await this.baseEndpoint.post(
        '/user/update',
        data,
        {
          headers: {
            authorization: `JWT ${user$.value.accessToken}`,
          },
        },
      );
    } catch (error) {
      throw error.response;
    }
  }

  async getPreview(id) {
    try {
      const response = await this.baseEndpoint.get(
        `/posting/preview/${id}`,
        {
          headers: {
            'smi-cup': process.env.VUE_APP_CUP_ID,
            authorization: `JWT ${user$.value.accessToken}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async downloadNearLiveContent(data) {
    return this.getSignedNearLiveUrl(data, true);
  }

  async getSignedNearLiveUrl(data, directDownload = false) {
    if (data.postingId) {
      const { postingId } = data;

      try {
        const params = {
          postingId,
          download: directDownload,
        };
        const response = await this.baseEndpoint.get(
          '/near-live/getSignedUrl',
          {
            params,
            headers: {
              'smi-cup': process.env.VUE_APP_CUP_ID,
              authorization: `JWT ${user$.value.accessToken}`,
            },
          },
        );

        return response.data;
      } catch (error) {
        throw error.response;
      }
    } else if (data.objectKey) {
      try {
        const response = await getSignedDownloadUrl(data.objectKey);
        await this.log({
          Action: LOG_ACTION_DOWNLOAD,
          EntityID: data.id,
          Entity: LOG_ENTITY_PMA,
        });

        return response.data;
      } catch (error) {
        throw error.response;
      }
    }

    return null;
  }

  async fcm(token = null) {
    try {
      await this.baseEndpoint.post(
        '/user/fcm',
        {
          token,
        },
        {
          headers: {
            'smi-cup': process.env.VUE_APP_CUP_ID,
            authorization: `JWT ${user$.value.accessToken}`,
          },
        },
      );
    } catch (error) {
      throw error.response;
    }
  }
}

const backendService = new BackendService();

export { backendService };
