<template>
  <modal
    class="smp-onboardingModal"
    :show-close-button="false">
    <template v-slot:header>
      <div v-if="step === 1">
        <h2>
          {{ $t('onboarding.headlines.welcome') }}
        </h2>
      </div>
      <div v-if="step === 2">
        <h2>
          <fa-icon
            icon="bell"
            class="mr-1" />
          {{ $t('onboarding.headlines.notifications') }}
        </h2>
      </div>
      <div v-if="step === 3">
        <h2>
          <fa-icon
            icon="user-cog"
            class="mr-1" />
          {{ $t('onboarding.headlines.account') }}
        </h2>
      </div>
    </template>

    <template v-slot:content>
      <div v-if="step === 1">
        <p v-html="$t('onboarding.content.welcome', { cup: cupProfile.title })" />
      </div>

      <div v-if="step === 2">
        <template v-if="supportsNotifications">
          <p>
            {{ $t('onboarding.content.notifications.base') }}
          </p>

          <p v-if="notificationPermissionState === 'granted'">
            {{ $t('onboarding.content.notifications.alreadyGranted') }}
          </p>
          <p v-if="notificationPermissionState === 'denied'">
            {{ $t('onboarding.content.notifications.alreadyDenied') }}
          </p>
        </template>
        <p v-else>
          {{ $t('onboarding.content.notifications.notSupported') }}
        </p>
      </div>

      <div v-if="step === 3">
        <p>
          {{ $t('onboarding.content.account') }}
        </p>
      </div>
    </template>

    <template v-slot:footer>
      <div v-if="step === 1">
        <button
          class="smp-button smp-button-primary"
          @click.prevent="nextStep">
          {{ $t('onboarding.actions.welcome') }}
        </button>
      </div>

      <div v-if="step === 2">
        <div
          class="flex justify-center"
          v-if="notificationPermissionState === 'default' && supportsNotifications">
          <button
            class="smp-button smp-button-light mr2"
            :disabled="isLoading"
            @click.prevent="nextStep">
            {{ $t('onboarding.actions.notifications.no') }}
          </button>
          <button
            class="smp-button smp-button-primary ml2"
            :disabled="isLoading"
            @click.prevent="requestNotificationPermission">
            <fa-icon
              v-if="isLoading"
              icon="spinner"
              spin />
            <template v-else>
              {{ $t('onboarding.actions.notifications.yes') }}
            </template>
          </button>
        </div>

        <div v-if="notificationPermissionState !== 'default' || !supportsNotifications">
          <button
            class="smp-button smp-button-primary"
            @click.prevent="nextStep">
            {{ $t('onboarding.actions.notifications.next') }}
          </button>
        </div>
      </div>

      <div v-if="step === 3">
        <button
          class="smp-button smp-button-primary"
          @click.prevent="finish">
          <fa-icon
            v-if="isLoading"
            icon="spinner"
            spin />
          <template v-else>
            {{ $t('onboarding.actions.account') }}
          </template>
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { notificationsEnabled$ } from '@/util/subjects';
import { backendService } from '@/services/BackendService';
import logger from '@/util/logger';

export default {
  data() {
    return {
      step: 1,
      notificationPermissionState: null,
      isLoading: false,
    };
  },
  name: 'Onboarding',
  computed: {
    ...mapState(['cupProfile', 'supportsNotifications']),
    ...mapState('user', ['isOnboardingVisible']),
  },
  created() {
    if (this.supportsNotifications) {
      this.notificationPermissionState = Notification.permission;
    }
  },
  methods: {
    ...mapActions('user', ['hideOnboardingView']),
    nextStep() {
      this.step += 1;
    },
    async finish() {
      this.isLoading = true;

      try {
        await backendService.updateUser({
          hasSeenOnboarding: true,
        });
      } catch (e) {
        // noop
      }

      this.isLoading = false;
      this.hideOnboardingView();
    },
    async requestNotificationPermission() {
      this.isLoading = true;

      try {
        Notification.requestPermission().then((result) => {
          if (result === 'granted') {
            notificationsEnabled$.next(true);
          }
          this.isLoading = false;
          this.nextStep();
        });
      } catch (e) {
        logger.captureException(e);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-onboardingModal {
  .smp-modal {
    width: 400px;
  }

  .smp-modal-content {
    text-align: center;
    line-height: 1.25;
  }

  .smp-modal-header {
    h2 {
      .ms(3);
      margin: 0;
    }
  }
}
</style>
