import Vue from 'vue';
import VueFlicking from '@egjs/vue-flicking';
import { isSupported } from 'firebase/messaging';
import App from './App.vue';
import router from './router';
import store from '@/store';
import '@/util/icons';
import { i18n } from '@/util/i18n';
import { cloneObject } from '@/util/utils';
import logger from '@/util/logger';
import '@/util/validator';
import { notification } from '@/util/noty';
import Modal from '@/components/Modal.vue';
import { notificationsEnabled$ } from '@/util/subjects';
import { setupFCM } from '@/util/firebase';
import { backendService } from '@/services/BackendService';

logger.init();

Vue.config.productionTip = false;
Vue.prototype.$cloneObject = cloneObject;
Vue.prototype.$notification = notification;

Vue.component('Modal', Modal);

Vue.use(VueFlicking);

// Check if the browser supports web notifications
isSupported().then((result) => {
  store.dispatch('setSupportsNotificationsState', result);
});

notificationsEnabled$.subscribe((enabled) => {
  if (enabled) {
    setupFCM((token) => backendService.fcm(token));
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
