<template>
  <modal
    v-if="isAccountViewVisible"
    class="smp-accountModal"
    :show-close-button="true"
    @close="close">
    <template v-slot:header>
      <h2>
        <fa-icon
          icon="user-cog"
          class="mr2" />
        {{ $t('userProfile.headline') }}
      </h2>
    </template>
    <template v-slot:content>
      <account />
    </template>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Account from './Account.vue';

export default {
  name: 'AccountModal',
  components: { Account },
  computed: {
    ...mapState('user', ['isAccountViewVisible']),
  },
  methods: {
    ...mapActions('user', ['hideAccountView']),
    close() {
      this.hideAccountView();
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-accountModal {
  .smp-modal-header {
    h2 {
      font-size: 1.5rem;
      margin: 0;
      text-align: left;
    }
  }
}
</style>
