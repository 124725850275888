import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { ReplaySubject } from 'rxjs';
import en from '@/lang/en.json';

Vue.use(VueI18n);

const currentLocale$ = new ReplaySubject(1);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
});

const persistLocale = async (locale) => {
  try {
    localStorage.setItem('smiLocale', locale);
  } catch (e) {
    // noop
  }
};

const changeLocale = async (locale) => {
  const lang = locale.substr(0, 2);

  if (i18n.locale !== lang) {
    const messages = (await import(/* webpackChunkName: "[request]" */ `@/lang/${lang}.json`)).default;
    i18n.setLocaleMessage(lang, messages);
    i18n.locale = lang;
  }
};

currentLocale$.subscribe(async (locale) => {
  await changeLocale(locale);
  await persistLocale(locale);
});

const loadLocale = async () => {
  try {
    const locale = localStorage.getItem('smiLocale');

    if (locale) {
      currentLocale$.next(locale);
    }
  } catch (e) {
    // noop
  }
};

loadLocale();

const getCategoryLabel = (categoryId, list, locale) => {
  const category = list.find((c) => c.id === parseInt(categoryId));

  if (category) {
    return category.title[locale];
  }

  return null;
};

const getTeamLabel = (teamCode, list, locale) => {
  const team = list.find((t) => t.code === teamCode);

  if (team) {
    return team.name[locale];
  }

  return null;
};

export {
  i18n, getCategoryLabel, getTeamLabel, changeLocale, currentLocale$,
};
