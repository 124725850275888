const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

/**
 * Check if the passed click event should close an open element.
 *
 * Default is true, false only if the passed notCloseForClass is in the event target path.
 *
 * @param {MouseEvent} e
 * @param {string} notCloseForClass
 * @return {boolean}
 */
const shouldCloseOnClick = (e, notCloseForClass) => {
  let el = e.target;

  if (!el) return true;

  while (el.nodeName !== 'BODY') {
    if (el.classList && el.classList !== null && el.classList.contains(notCloseForClass)) {
      return false;
    }

    el = el.parentElement;

    // Break if the parentElement is null, this can happen if the the click was inside an
    // element which is hidden right after the click
    if (!el) return false;
  }

  return true;
};

const debounce = function (func, wait) {
  let timeout;

  return function debounced(...args) {
    const context = this;

    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const arrayCompareFunction = (key, order = 'asc') => (a, b) => {
  if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

  const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
  const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

  let comparison = 0;
  if (varA > varB) comparison = 1;
  else if (varA < varB) comparison = -1;

  return order === 'desc' ? comparison * -1 : comparison;
};

const sortArray = (array, key, order = 'asc') => array.sort(arrayCompareFunction(key, order));

const getIconForPostingType = (type) => {
  switch (type) {
  case 'image':
    return ['fas', 'image'];
  case 'interactive_image':
    return ['fas', 'images'];
  case 'info':
    return ['fas', 'chart-bar'];
  case 'clip':
    return ['fas', 'video'];
  case 'multi_clip':
    return ['fas', 'video'];
  case 'clip-360':
    return ['fas', 'vr-cardboard'];
  default:
    return ['fas', 'question-circle'];
  }
};

const isDev = () => process.env.NODE_ENV === 'development';

export {
  cloneObject, shouldCloseOnClick, debounce, sortArray, getIconForPostingType,
  isDev,
};
