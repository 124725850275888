<template>
  <div
    class="smp-dropdownFilter smp-singleSelectFilter"
    :class="{'is-open': isOpen }">
    <div
      class="smp-dropdownFilter-toggle smp-singleSelectFilter-toggle smp-button smp-button-primary"
      :class="{'smp-dropdownFilter-toggle--hasSubLabel smp-singleSelectFilter-toggle--hasSubLabel': selectedOption.subLabel}"
      @click="toggle">
      <i
        v-if="icon"
        class="mr2">
        <fa-icon :icon="icon" />
      </i>
      <div
        class="flex-auto smp-singleSelectFilter-labelWrapper"
        :class="{'smp-singleSelectFilter-labelWrapper--hasSubLabel': selectedOption.subLabel}">
        <span class="smp-singleSelectFilter-label">{{ selectedOption.label }}</span>
        <span
          v-if="selectedOption.subLabel"
          class="smp-singleSelectFilter-subLabel">
          {{ selectedOption.subLabel }}
        </span>
      </div>
      <div class="smp-dropdownFilter-toggleIndicator smp-singleSelectFilter-toggleIndicator">
        <fa-icon :icon="['fas', 'angle-down']" />
      </div>
    </div>

    <div
      v-show="isOpen"
      class="smp-dropdownFilter-content smp-singleSelectFilter-content">
      <div class="smp-dropdownFilter-options smp-singleSelectFilter-options">
        <div
          class="smp-dropdownFilter-option smp-singleSelectFilter-option"
          v-for="option in options"
          :key="option.value"
          @click.prevent="select(option)">
          <template
            v-if="option.icon && Array.isArray(option.icon)">
            <i
              class="mr2">
              <fa-icon :icon="option.icon" />
            </i>
          </template>

          <template
            v-if="option.icon && typeof option.icon === 'string'">
            <span
              class="mr2"
              v-html="option.icon" />
          </template>

          <div class="smp-singleSelectFilter-option-labelWrapper">
            <span class="smp-singleSelectFilter-option-label">
              {{ option.label }}
            </span>

            <span
              v-if="option.subLabel"
              class="smp-singleSelectFilter-option-subLabel">
              {{ option.subLabel }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownMixin from '@/mixins/DropDown';

/**
   * Class for a single "multi filter".
   *
   * Currently just a list of checkboxes, but could also be for example a multi select control.
   */
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    filterKey: {
      type: String,
      required: true,
    },
    icon: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      containerClass: 'smp-singleSelectFilter',
    };
  },
  computed: {
    selectedOption() {
      const selected = this.options.find((option) => option.active);

      return selected || this.options[0];
    },
  },
  mixins: [DropDownMixin],
  methods: {
    select(option) {
      if (this.filterKey === 'sort') {
        this.$store.dispatch('setSortOrder', option.value);
        this.close();
      } else {
        this.$emit('input', option);
      }
    },
  },
};
</script>
