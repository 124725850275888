<template>
  <div class="smp-downloadButton">
    <template v-if="!hasMultipleAssets">
      <button
        :class="buttonClasses"
        @click.prevent="download(assets[0])">
        <i class="mr2"><fa-icon :icon="['fas', 'download']" /></i>
        {{ $t('posting.downloadCta') }}
      </button>
    </template>

    <template v-else>
      <button
        :class="buttonClasses"
        @click.prevent="toggleDropDown">
        {{ $t('posting.downloadCta') }}

        <i class="ml2"><fa-icon :icon="['fas', 'angle-down']" /></i>
      </button>

      <div
        class="smp-downloadButton-dropdown smp-buttonGroup"
        v-if="isOpen">
        <button
          v-for="asset in assets"
          :key="`posting-asset-${asset.id}`"
          :class="buttonClasses"
          @click.prevent="download(asset)">
          <div class="smp-downloadButton-label">
            <template v-if="asset.title[currentLocale]">
              {{ asset.title[currentLocale] }}
            </template>
            <template v-else-if="asset.title[defaultLocale]">
              {{ asset.title[defaultLocale] }}
            </template>
            <template v-else-if="asset.filename">
              {{ asset.filename }}
            </template>
          </div>
          <div class="ml2">
            <span
              class="ml2"
              v-if="asset.size">
              {{ asset.size }}
            </span>
            <i class="ml2"><fa-icon :icon="['fas', 'download']" /></i>
          </div>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Posting from '@/mixins/Posting';
import { backendService } from '@/services/BackendService';
import logger from '@/util/logger';
import { shouldCloseOnClick } from '@/util/utils';

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    buttonClasses: {
      type: String,
      required: false,
      default: 'smp-button smp-button-primary',
    },
  },
  mixins: [Posting],
  computed: {
    ...mapState(['currentLocale']),
    ...mapGetters(['defaultLocale']),
  },
  watch: {
    isOpen(val) {
      if (val) {
        window.addEventListener('click', this.clickListener, false);
      } else {
        window.removeEventListener('click', this.clickListener, false);
      }
    },
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    },
    openDropDown() {
      this.isOpen = true;
    },
    clickListener(e) {
      if (shouldCloseOnClick(e, 'smp-downloadButton')) { this.closeDropDown(); }
    },
    async download(asset) {
      try {
        const url = await backendService.download(asset.uuid);

        if (url) {
          window.location.href = url;
        }
      } catch (e) {
        logger.captureException(e);

        let message = e?.data?.message ?? 'generic_error';

        const messageKey = `generic.serverMessages.${message}`;
        if (this.$t(messageKey) !== messageKey) {
          message = this.$t(messageKey);
        }

        this.$notification.error(message);
      }

      this.closeDropDown();
    },
  },
};
</script>

<style lang="less">
  .smp-downloadButton {
    position: relative;

    .smp-button {
      white-space: nowrap;
    }

    .smp-downloadButton-dropdown {
      position: absolute;
      z-index: 90;

      .smp-downloadButton-label {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
