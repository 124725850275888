<template>
  <div class="smp-filterMenu col-12">
    <div class="smp-filterMenu-main flex">
      <div class="smp-filterMenu-left flex flex-wrap flex-auto">
        <multi-select-filter
          v-show="showTypeFilter"
          filter-key="types"
          :options="typeOptions"
          :label="$t('filter.type.title')"
          :icon="['far', 'image']"
          use-posting-type-icons />

        <multi-select-filter
          v-show="showCategoryFilter"
          filter-key="categories"
          :options="categoryOptions"
          :label="$t('filter.category.title')"
          :icon="['fas', 'table']" />

        <multi-select-filter
          v-show="showTeamsFilter"
          filter-key="teams"
          :options="teamOptions"
          :label="$t(`generic.contestants.${contestantType}.plural`)"
          :icon="['fas', 'users']"
          group-by-state />

        <multi-select-filter
          v-show="showTagsFilter"
          filter-key="tags"
          :options="tagOptions"
          :label="$t('filter.tags.title')"
          :icon="['fas', 'tags']" />

        <multi-select-filter
          filter-key="filterTags"
          v-for="filter of customFilters"
          :key="'custom-filter-' + filter.id"
          :label="filter.title"
          :options="filter.options" />

        <date-picker
          v-show="showDateFilter"
          @changed="setDate($event)"
          ref="postingDatePicker" />

        <is-recommended-filter v-show="showRecommendedFilter" />
      </div>

      <div class="smp-filterMenu-right">
        <single-select-filter
          filter-key="sort"
          :options="sortOptions"
          :icon="sortIcon" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import MultiSelectFilter from '@/components/MultiSelectFilter.vue';
import SingleSelectFilter from '@/components/SingleSelectFilter.vue';
import DatePicker from '@/components/DatePicker.vue';
import { getIconForPostingType } from '@/util/utils';
import IsRecommendedFilter from '@/components/IsRecommendedFilter.vue';

export default {
  components: {
    MultiSelectFilter, DatePicker, SingleSelectFilter, IsRecommendedFilter,
  },
  computed: {
    ...mapState([
      'activeTags',
      'activeFilterTags',
      'activeTab',
      'cupProfile',
      'currentLocale',
    ]),
    ...mapGetters([
      'availableTypes',
      'allCategories',
      'allTeams',
      'availableTags',
      'types',
      'sortOptions',
      'sortOrder',
      'isRecommended',
      'aggregations',
      'tabs',
      'defaultLocale',
      'activeTabObject',
      'contestantType',
    ]),
    typeOptions() {
      const aggs = this.aggregations?.types?.map((bucket) => bucket.key) ?? [];

      return this.availableTypes.map((value) => ({
        value,
        label: this.$t(`posting.type.${value.toLowerCase()}`),
        active: this.types.indexOf(value) > -1,
        icon: getIconForPostingType(value),
        available: aggs.includes(value),
      }));
    },
    categoryOptions() {
      const aggs = this.aggregations?.categories?.map((bucket) => bucket.key) ?? [];

      return this.allCategories.map((c) => ({
        ...c,
        available: aggs.includes((c.value)),
      }));
    },
    teamOptions() {
      const aggs = this.aggregations?.teams?.map((bucket) => bucket.key) ?? [];

      return this.allTeams.map((t) => {
        const row = {
          ...t,
          available: aggs.includes((t.value)),
        };

        if (t.countryCode) {
          if (t.isFifaCode) {
            row.icon = `<span class="flag flag-fifa-${t.countryCode.toLowerCase()}"/>`;
          } else {
            row.icon = `<span class="flag flag-${t.countryCode.toLowerCase()}"/>`;
          }
        }

        return row;
      });
    },
    tagOptions() {
      const aggs = this.aggregations?.tags?.map((bucket) => bucket.key) ?? [];

      return this.availableTags.map((value) => ({
        value,
        label: value,
        active: this.activeTags.indexOf(value) > -1,
        available: aggs.includes(value),
      }));
    },
    sortIcon() {
      const icon = ['fas'];
      if (this.sortOrder.indexOf('asc') > -1) icon.push('sort-amount-up');
      else icon.push('sort-amount-down');

      return icon;
    },
    filterConfigForActiveTab() {
      return this.activeTabObject?.filterConfig;
    },
    showTypeFilter() {
      return this.filterConfigForActiveTab?.showTypeFilter;
    },
    showCategoryFilter() {
      return this.filterConfigForActiveTab?.showCategoryFilter;
    },
    showTeamsFilter() {
      return this.filterConfigForActiveTab?.showTeamsFilter;
    },
    showTagsFilter() {
      return this.filterConfigForActiveTab?.showTagsFilter;
    },
    showDateFilter() {
      return this.filterConfigForActiveTab?.showDateFilter;
    },
    showRecommendedFilter() {
      return this.filterConfigForActiveTab?.showRecommendedFilter;
    },
    customFilters() {
      const customFilterIdsForTab = this.activeTabObject?.customFilterIds;
      if (customFilterIdsForTab) {
        const aggs = this.aggregations?.filterTags?.map((bucket) => bucket.key) ?? [];

        return this.cupProfile.filters
          ?.filter((f) => customFilterIdsForTab.includes(f.id))
          .map((f) => ({
            ...f,
            title: f.title[this.currentLocale] ?? f.title[this.defaultLocale],
            options: f.options.map((value) => ({
              active: this.activeFilterTags.indexOf(value) > -1,
              available: aggs.includes(value),
              label: value,
              value,
            })),
          }));
      }

      return [];
    },
  },
  methods: {
    ...mapActions(['setDate', 'setIsRecommended']),
    show() {
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
    toggle() {
      if (this.isOpen) this.hide();
      else this.show();
    },
    isTypeAvailable(type) {
      return this.availableTypes.hasOwnProperty(type);
    },
  },
};
</script>

<style lang="less">
  @import (reference) '~@/styles/vars';

  .smp-filterMenu {
    margin-top: @space-3;

    .smp-filterMenu-left, .smp-filterMenu-right {
      > div {
        margin-bottom: @space-2;

        &:not(:last-child) {
          margin-right: @space-2;
        }
      }
    }
  }
</style>
