<template>
  <div class="player-360">
    <video
      ref="video"
      class="video-js vjs-16-9 vjs-default-skin"
      crossorigin="anonymous">
      <source
        :src="signedClipUrl"
        type="video/mp4">
    </video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'videojs-vr';
import { mapActions } from 'vuex';
import PostingMixin from '@/mixins/Posting';

import LogService from '@/services/LogService';
import logger from '@/util/logger';

export default {
  data() {
    return {
      signedClipUrl: null,
      player: null,
      playerOptions: {
        controls: true,
        autoplay: true,
        playsinline: true,
        poster: this.posting.previewImage,
      },
      destroyed: false,
    };
  },
  props: {
    posting: {
      type: Object,
      required: true,
    },
  },
  mixins: [PostingMixin],
  beforeDestroy() {
    this.destroyPlayer();
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(['getSignedUrl']),
    init() {
      if (this.destroyed) return;

      this.getSignedUrl({ assetId: this.primaryAsset.uuid }).then((url) => {
        if (this.destroyed) { return; }

        this.signedClipUrl = url;
        this.$emit('loaded');

        this.$nextTick(() => {
          this.player = videojs(this.$refs.video, this.playerOptions);
          this.player.vr({ projection: '360' });

          // Log player events: https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
          ['play', 'pause', 'ended', 'seeked', 'waiting'].forEach((event) => {
            this.player.on(event, () => LogService.event('Player', event, this.posting.category, this.posting.id));
          });

          // Explicitly throw error for sending to Sentry
          this.player.on('error', (e) => {
            logger.captureException(e);
          });
        });
      });
    },
    destroyPlayer() {
      this.destroyed = true;

      if (this.player) {
        this.player.dispose();
        this.player = null;
      }
    },
  },
};
</script>

<style lang="less">
  @import (reference) '~@/styles/vars';
  @import '~video.js/dist/video-js.min.css';
  @import '~videojs-vr/dist/videojs-vr.css';

  .player-360 {
    display: block;
    width: 1265px; // 1280 - 7.5px padding on both sides
    max-width: 100%;
    height: 0;
    padding-bottom: 56.25%; // 16:9 aspect ratio
    cursor: pointer;

    @media screen and (max-width: (@breakpoint-lg - 1)) {
      height: 40vh;
      width: calc(40vh * 16 / 9);
      padding: 0;
      display: flex;
      align-items: center;
    }

    .vjs-button-vr {
      display: none !important;
    }
  }
</style>
