export default {
  isReady(state, getters, rootState) {
    return !!rootState.cupProfile;
  },
  availableMatches(state, getters, rootState, rootGetters) {
    return rootGetters.matches;
  },
  currentMatchesRange(state, getters, rootState) {
    return rootState.cupProfile?.currentMatchesRange;
  },
};
