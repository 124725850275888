export default class LogService {
  static useGA4(propertyId) {
    return propertyId && typeof propertyId === 'string' && propertyId[0] === 'G';
  }

  /* eslint-disable */
  static init(propertyId) {
    if (process.env.NODE_ENV === 'production') {
      if (LogService.useGA4(propertyId)) {
        {
          (function (i, s, o, g, r, a, m) {
            i.GoogleAnalyticsObject = r;
            i[r] = i[r] || function () {
              (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
              m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
          }(window, document, 'script', 'https://www.googletagmanager.com/gtag/js', 'ga'));
        }

        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', propertyId, { anonymize_ip: true });
      } else {
        {
          (function (i, s, o, g, r, a, m) {
            i.GoogleAnalyticsObject = r;
            i[r] = i[r] || function () {
              (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
              m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
          }(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga'));
        }

        ga('create', propertyId, 'auto');
        ga('set', 'anonymizeIp', true);
        ga('send', 'pageview');
      }
    }
  }
  /* eslint-enable */

  static event(category, action, label = null, value = null) {
    if (process.env.NODE_ENV === 'production' && (typeof ga !== 'undefined' || typeof gtag !== 'undefined')) {
      if (typeof gtag !== 'undefined') {
        window.gtag('event', action, {
          event_category: category,
          event_label: label || undefined,
          event_value: value || undefined,
        });
      } else {
        window.ga('send', 'event', category, action, label || undefined, value || undefined);
      }
    } else {
      console.log(
        '[LOG] Action "%s" of category "%s" occured. Label: "%s", Value: %s',
        action,
        category,
        label,
        value,
      );
    }
  }
}
