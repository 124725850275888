<template>
  <div class="smp-video">
    <video
      class="smp-video-player"
      :key="`${asset.uuid}`"
      width="1280"
      controls
      controlsList="nodownload"
      ref="player"
      :class="{'smp-video-player--active': isActive}">
      <source
        :src="signedUrl"
        type="video/mp4">
      {{ $t('posting.preview.html5VideoNotSupported') }}
    </video>

    <div
      class="smp-video-loader"
      v-if="!isActive">
      <fa-icon
        icon="spinner"
        spin />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LogService from '@/services/LogService';
import logger from '@/util/logger';

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
    posting: {
      type: Object,
      required: true,
    },
    destroyed: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      signedUrl: null,
    };
  },
  created() {
    this.init();
  },
  watch: {
    isActive() {
      if (!this.isActive) {
        this.stopVideo();
      }
    },
  },
  methods: {
    ...mapActions(['getSignedUrl']),
    async init() {
      this.$nextTick(() => {
        if (this.$refs.player) this.$refs.player.load();
      });

      try {
        const url = await this.getSignedUrl({
          assetId: this.asset.uuid,
        });

        if (this.destroyed) { return; }

        this.signedUrl = url;

        this.$nextTick(() => {
          const { player } = this.$refs;

          if (player) {
            player.addEventListener('canplay', this.canPlayHandler, false);
            player.load();

            const logTmp = { category: this.posting.category, id: this.posting.id };
            // Log player events: https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
            ['play', 'pause', 'ended', 'seeked', 'waiting'].forEach((event) => {
              player.addEventListener(event, () => LogService.event('Player', event, logTmp.category, logTmp.id));
            });

            // Explicitly throw error for sending to Sentry
            player.addEventListener('error', (e) => {
              logger.captureException(e);
            });
          }
        });
      } catch (e) {
        logger.captureException(e);

        let message = e?.data?.message ?? 'generic_error';

        const messageKey = `generic.serverMessages.${message}`;
        if (this.$t(messageKey) !== messageKey) {
          message = this.$t(messageKey);
        }

        this.$emit('error', message);
        this.$notification.error(message);
      }
    },
    startVideo() {
      // Break if the canplay callback fires after the component was already destroyed
      // Can happen on fast clicks on the overlay while the video is still loading
      if (this.destroyed) { return; }

      // Get the player and start video
      const { player } = this.$refs;

      // Check if the player was found, break otherwise.
      // Happens when the method is called after the user has already navigated to the another post
      if (!player) return;

      let promise = player.play();

      // Check if a promise was returned and catch it
      if (promise !== undefined) {
        promise.catch(() => {
          // Mute the player and try again
          player.muted = true;
          promise = player.play();

          if (promise !== undefined) {
            promise.catch(() => {
              LogService.event('Player', 'Autoplay prevented', this.posting.category, this.posting.id);
            });
          }
        });
      }
    },
    stopVideo() {
      // Get the player and start video
      const { player } = this.$refs;

      if (player) {
        player.pause();
      }
    },
    canPlayHandler() {
      this.$emit('loaded');

      if (this.autoplay) {
        this.startVideo();
      }
    },
  },
  beforeDestroy() {
    // Remove the canplay listener if the player could be fetched
    const { player } = this.$refs;
    if (player) { player.removeEventListener('canplay', this.canPlayHandler); }
  },
};
</script>

<style lang="less">
@import (reference) '~@/styles/base';

.smp-video {
  position: relative;

  .smp-video-player {
    visibility: hidden;

    &.smp-video-player--active {
      visibility: visible;
    }
  }

  .smp-video-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ms(4);
    color: @color-mono-80;
  }
}
</style>
