<template>
  <transition name="fade">
    <div class="smp-overlay smp-mobileFilterMenu">
      <transition name="zoom">
        <div class="smp-modal smp-overlay-boxShadow smp-mobileFilterMenu-modal">
          <div class="smp-modal-header mb4">
            {{ $t('filter.mobile.headline') }}
          </div>

          <div class="smp-modal-content">
            <search-field class="mb4" />

            <filter-menu />

            <div class="mt4 flex justify-between mxn2">
              <button
                class="smp-button smp-button-danger smp-button--large smp-button--fullWidth m1"
                @click.prevent="reset">
                {{ $t('filter.mobile.reset') }}
              </button>
              <button
                class="smp-button smp-button-primary smp-button--large smp-button--fullWidth m1"
                @click.prevent="close">
                {{ $t('filter.mobile.closeCta') }}
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FilterMenu from '@/components/FilterMenu.vue';
import SearchField from '@/components/SearchField.vue';
import Overlay from '@/mixins/Overlay';

export default {
  data() {
    return {
      containerClass: 'smp-mobileFilterMenu',
    };
  },
  components: { FilterMenu, SearchField },
  mixins: [Overlay],
  computed: {
    ...mapGetters(['mobileFilterMenuVisible']),
  },
  methods: {
    ...mapActions(['resetFilters']),
    reset() {
      this.resetFilters();
      this.close();
    },
  },
};
</script>

<style lang="less">
  @import (reference) '~@/styles/base';
  @import '~@/styles/overlay';

  .smp-mobileFilterMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;

    .smp-modal {
      padding: @space-4;
      background-color: @color-mono-100;
      border-radius: @defaultBorderRadius;
      width: 400px;
      max-width: 95%;

      .smp-modal-header {
        font-size: 2rem;
        text-align: center;
      }

      .smp-filterMenu-main {
        display: block;

        .smp-filterMenu-left {
          display: block;
        }

        .smp-filterMenu-left, .smp-filterMenu-right {
          > div, button {
            margin-bottom: @space-2;

            &:not(:last-child) {
              margin-right: 0;
            }
          }
        }

        .smp-filterMenu-right {
          margin-top: @space-4;
        }

        .smp-button {
          width: 100%;
          text-align: left;
        }

        .smp-isRecommendedFilter {
          //background: @color-mono-94;
        }
      }

      .smp-multiSelectFilter {
        .smp-multiSelectFilter-content {
          left: 50%;
          transform: translate(-50%, 100%);
          max-width: 95vw;
          max-height: 216px; // 4 elements
          overflow: auto;
          -webkit-overflow-scrolling: touch;
        }
      }
    }

    @media screen and (max-width: (@breakpoint-xs - 1)) {
      .smp-modal {
        width: 95%;
      }
    }
  }
</style>
