<template>
  <div id="app">
    <router-view
      v-if="authInitialised && cupProfile" />

    <div
      class="app--loading"
      v-else>
      <fa-icon
        icon="spinner"
        spin />
    </div>

    <account-modal />
    <onboarding
      v-if="isOnboardingVisible" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import '@/styles/thirdparty-sass.scss';
import AccountModal from '@/components/user-account/AccountModal.vue';
import Onboarding from '@/components/Onboarding.vue';

export default {
  components: { AccountModal, Onboarding },
  computed: {
    ...mapState(['authInitialised', 'cupProfile']),
    ...mapState('user', ['isOnboardingVisible']),
  },
};
</script>

<style lang="less">
@import "~@/styles/base";
@import "~@/styles/button";
@import "~@/styles/filter";
@import "~@/styles/form";
@import "~@/../node_modules/flag-css/dist/less/flag-css";
@import "~@egjs/vue-flicking/dist/flicking.css";
@import "~@egjs/flicking-plugins/dist/pagination.css";
@import "~@egjs/flicking-plugins/dist/arrow.css";
@flag-css-path: 'https://res.cloudinary.com/level51/image/upload/v1/social-media-platform/static/flags';
@flag-css-png-path: @flag-css-path;

// Bug fix Andorra flag, should be part of flag-css but there seems to be a bug in the less
// version, see line 56 in flag-css/dist/less/flag-list.less
.flag-and, .flag-ad, .flag-20, .flag-ioc-and, .flag-fifa-and {
  background-image: ~"url(@{flag-css-png-path}/and.png)";
  background-image: ~"url(@{flag-css-path}/and.svg)";
}

#app {
  .app--loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ms(8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10900;
    color: @color-primary;
  }

  .flicking-viewport {
    .flicking-pagination-bullet {
      background: fade(@color-mono-100, 70);
      width: 10px;
      height: 10px;
      position: relative;
      .default-boxShadow;

      &.flicking-pagination-bullet-active {
        background: @color-mono-100;

        &:after {
          content: "";
          background: @color-primary;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .flicking-arrow-next, .flicking-arrow-prev {
      &:before, &:after {
        background: @color-mono-100;
        .default-boxShadow;
        transition: background-color 250ms ease-in-out;
      }

      &:hover {
        &:before, &:after {
          background: @color-primary;
        }
      }

      &.flicking-arrow-disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
</style>
