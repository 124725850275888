import { shouldCloseOnClick } from '@/util/utils';

export default {
  mounted() {
    document.getElementsByTagName('body')[0].classList.add('overlay-open');

    setTimeout(() => {
      window.addEventListener('keyup', this.handleKeyup, false);
      window.addEventListener('click', this.handleClick, false);
    }, 1);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyup, false);
    window.removeEventListener('click', this.handleClick, false);

    document.getElementsByTagName('body')[0].classList.remove('overlay-open');
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleKeyup(e) {
      if (e.keyCode === 27) { this.close(); }
    },
    handleClick(e) {
      if (shouldCloseOnClick(e, this.containerClass)) {
        this.close();
      }
    },
  },
};
