import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import {
  faTimes, faDownload, faImage, faChartBar, faVideo, faVrCardboard, faQuestionCircle, faAngleDown,
  faCheck, faSearch, faSignOutAlt, faSpinner, faAngleRight, faAngleLeft, faTable, faSortAmountDown,
  faSortAmountUp, faSortUp, faSortDown, faUsers, faArrowLeft, faSyncAlt, faFilter,
  faAngleUp, faExclamationTriangle, faSignInAlt, faPlus, faTags, faExclamation, faFutbol,
  faTh, faStream, faUser, faSave, faLanguage, faUserCog, faImages, faInfoCircle, faBell,
  faPhotoVideo, faSquare, faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt, faImage as faImageRegular, faSquare as faSquareRegular, faCheckSquare,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faTimes, faDownload, faImage, faChartBar, faVideo, faVrCardboard, faQuestionCircle, faAngleDown,
  faCheck, faSearch, faSignOutAlt, faSpinner, faAngleRight, faAngleLeft, faCalendarAlt,
  faImageRegular, faTable, faSortAmountDown, faSortAmountUp, faSortUp, faSortDown, faUsers,
  faArrowLeft, faSyncAlt, faAngleUp, faExclamationTriangle, faSignInAlt, faSquare, faCheckSquare,
  faFilter, faPlus, faTags, faExclamation, faFutbol, faTh, faStream, faUser, faSave, faBell,
  faLanguage, faUserCog, faImages, faInfoCircle, faPhotoVideo, faSquareRegular, faCheckCircle,
);

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);
