import Noty from 'noty';
import '@/styles/noty.less';
import { icon as faIcon } from '@fortawesome/fontawesome-svg-core';
import '@/../node_modules/noty/lib/noty.css';

class Notification {
  constructor() {
    this.defaultOptions = {
      // relax, mint, metroui
      theme: 'mint',
      // top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight
      layout: 'bottomCenter',
      timeout: 3000,
      progressBar: true,
      animation: {
        speed: 250,
      },
    };
  }

  static addIconToText(text, iconName, prefix = 'fas') {
    const icon = faIcon({ prefix, iconName });
    return `<span class="smp-notyIcon">${icon.html}</span> ${text}`;
  }

  show(text, type, options = {}, triggerShow = true) {
    const noty = new Noty({
      text,
      type,
      ...this.defaultOptions,
      ...options,
    });

    if (triggerShow) noty.show();

    return noty;
  }

  info(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'exclamation'),
      'info',
      options,
      triggerShow,
    );
  }

  success(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'check'),
      'success',
      options,
      triggerShow,
    );
  }

  warning(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'exclamation-triangle'),
      'warning',
      options,
      triggerShow,
    );
  }

  error(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'exclamation-triangle'),
      'error',
      {
        ...this.defaultOptions,
        timeout: false,
        ...options,
      },
      triggerShow,
    );
  }

  showByType(text, type, options = {}, triggerShow = true) {
    switch (type) {
    case 'success':
      return this.success(text, options, triggerShow);
    case 'warning':
      return this.warning(text, options, triggerShow);
    case 'error':
      return this.error(text, options, triggerShow);
    default:
      return this.info(text, options, triggerShow);
    }
  }
}

const notification = new Notification();

export { notification, Notification };
