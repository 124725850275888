<template>
  <transition
    name="fade"
    enter-active-class="fadeInUp"
    leave-active-class="fadeOutDown">
    <div
      class="scroll-to-top"
      v-show="visible"
      @click.prevent="scrollTop">
      <fa-icon :icon="['fas', 'angle-up']" />
    </div>
  </transition>
</template>

<script>
import Velocity from 'velocity-animate';
import LogService from '@//services/LogService';

export default {
  data() {
    return {
      visible: false,
    };
  },
  props: {
    breakpoint: {
      type: Number,
      default: 1000,
    },
    duration: {
      type: Number,
      default: 400,
    },
    elements: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.addListener();
    this.checkPosition();
  },
  computed: {
    grid() {
      return this.elements.grid.$el;
    },
    wrapper() {
      return this.elements.gridWrapper;
    },
    header() {
      return this.elements.header.$el;
    },
  },
  methods: {
    addListener() {
      this.$nextTick(() => {
        this.wrapper.addEventListener('scroll', this.checkPosition, false);
      });
    },
    checkPosition() {
      const offset = this.wrapper.scrollTop;

      if (offset > this.breakpoint && !this.visible) {
        this.visible = true;
      } else if (offset < this.breakpoint && this.visible) {
        this.visible = false;
      }
    },
    scrollTop() {
      LogService.event('Navigation', 'scrollToTop');

      Velocity(this.grid, 'scroll', {
        duration: this.duration,
        offset: -(this.header.getBoundingClientRect().height + 24),
        container: this.wrapper,
      });
    },
  },
};
</script>

<style lang="less" scoped>
  @import (reference) '~@/styles/base';

  .scroll-to-top {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 20px;
    background: @color-primary;
    color: @color-text-for-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: @defaultBorderRadius;
    cursor: pointer;
    .ms(1);
    border: 2px solid @color-text-for-primary;
  }
</style>
