<template>
  <header
    class="smp-mainPageHeader">
    <div class="smp-mainPageHeader-row1">
      <div class="smp-container--large flex items-center">
        <div class="smp-mainPageHeader-left flex-none">
          <div class="smp-mainPageHeader-logoContainer" />
        </div>

        <div class="smp-mainPageHeader-right flex-auto flex justify-between items-center">
          <search-field class="mx5" />

          <div class="flex-none flex items-center">
            <button
              class="smp-button smp-button-primary mr2 smp-mainPageHeader-mobileFilterButton"
              @click.prevent="toggleMobileFilterMenu">
              <i class="mr2"><fa-icon :icon="['fas', 'filter']" /></i>
              {{ $t('filter.mobile.toggleCta') }}
            </button>

            <locale-menu v-if="showLocaleMenu" />

            <user-menu class="ml2" />
          </div>
        </div>
      </div>
    </div>

    <div class="smp-mainPageHeader-row2">
      <div class="smp-container--large flex items-center mt2">
        <filter-menu v-if="!isMobile" />
      </div>
    </div>

    <div class="smp-mainPageHeader-row3">
      <div class="smp-container--large flex items-center mt2">
        <div class="flex-none">
          {{ $t('generic.totalResults', { total: postingsTotal }) }}
        </div>

        <div class="ml2">
          <active-filters-bar />
        </div>
      </div>
    </div>

    <div
      v-if="updateAvailable"
      class="smp-updateAction">
      <button
        class="smp-button smp-button-primary smp-button--large"
        @click="$emit('triggerUpdate')">
        <i class="mr2">
          <fa-icon :icon="['fas', 'sync-alt']" />
        </i>
        {{ $t('generic.loadNewPostings') }}
      </button>
    </div>

    <tab-bar />
  </header>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SearchField from '@/components/SearchField.vue';
import LocaleMenu from '@/components/LocaleMenu.vue';
import UserMenu from '@/components/UserMenu.vue';
import FilterMenu from '@/components/FilterMenu.vue';
import ActiveFiltersBar from '@/components/ActiveFiltersBar.vue';
import TabBar from '@/components/TabBar.vue';

export default {
  components: {
    ActiveFiltersBar, SearchField, LocaleMenu, FilterMenu, TabBar, UserMenu,
  },
  computed: {
    ...mapState(['cupProfile']),
    ...mapGetters(['activeFilters', 'postingsTotal', 'updateAvailable', 'mobileFilterMenuVisible', 'isMobile', 'showLocaleMenu']),
  },
  methods: {
    ...mapActions(['toggleMobileFilterMenu']),
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/vars";

header.smp-mainPageHeader {
  position: relative;
  z-index: 9000;
  background: @color-mono-100;
  border-bottom: 2px solid @color-mono-70;
  padding: @space-3 0;

  .smp-mainPageHeader-mobileFilterButton {
    display: none;
  }

  .smp-updateAction {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  @media screen and (max-width: (@breakpoint-sm - 1)) {
    .smp-container--large {
      flex-wrap: wrap;
    }

    .smp-mainPageHeader-left {
      width: 100%;
      text-align: center;
    }

    .smp-mainPageHeader-right {
      margin-top: @space-2;
      width: 100%;
      justify-content: center;
    }

    .smp-mainPageHeader-mobileFilterButton {
      display: inline-block;
    }

    .smp-searchField,
    .smp-mainPageHeader-row2,
    .smp-mainPageHeader-row3 {
      display: none;
    }
  }
}
</style>
