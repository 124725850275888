import moment from 'moment-timezone';

import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

const authState$ = new ReplaySubject();

const defaultTimezone$ = new BehaviorSubject('UTC');
const userTimezone$ = new BehaviorSubject(moment.tz.guess() ?? 'UTC');
const creatorTimezone$ = new BehaviorSubject('UTC');

const user$ = new BehaviorSubject(null);

authState$.subscribe((user) => {
  user$.next(user);
});

const postingsToDelete$ = new BehaviorSubject(null);
const postingsToAdd$ = new BehaviorSubject(null);
const gridFetchRequired$ = new Subject();

const notificationsEnabled$ = new Subject();

export {
  authState$, user$, defaultTimezone$, userTimezone$, creatorTimezone$,
  postingsToAdd$, postingsToDelete$, gridFetchRequired$, notificationsEnabled$,
};
