<template>
  <div
    v-if="tabs && tabs.length > 1"
    class="smp-tabBar smp-container--large">
    <tab
      class="smp-tab"
      v-for="tab in tabs"
      :key="tab.id"
      :tab="tab" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tab from '@/components/Tab.vue';

export default {
  components: { Tab },
  computed: {
    ...mapGetters(['tabs']),
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/vars";
.smp-tabBar {
  display: flex;
  position: relative;
  bottom: -@space-3;
  margin-top: @space-2;
}
</style>
