<template>
  <transition name="fade">
    <div class="smp-overlay">
      <transition name="zoom">
        <div class="smp-modal smp-overlay-boxShadow">
          <div class="smp-modal-header pb3">
            <a
              class="smp-modal-closeBtn"
              v-if="showCloseButton"
              href=""
              @click.prevent="close">
              <fa-icon icon="times" />
            </a>
            <slot name="header" />
          </div>
          <div class="smp-modal-content">
            <slot name="content" />
          </div>
          <div class="smp-modal-footer pt4">
            <slot name="footer" />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import Overlay from '@/mixins/Overlay';

export default {
  mixins: [Overlay],
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      containerClass: 'smp-modal',
    };
  },
};
</script>

<style lang="less" scoped>
  @import (reference) '~@/styles/base';
  @import '~@/styles/overlay';

  .smp-modal {
    padding: @space-4;
    background-color: @color-mono-100;
    border-radius: @defaultBorderRadius;
    max-height: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
  }

  .smp-modal-header {
    font-size: 2rem;
    text-align: center;
    .flex-none;
    position: relative;

    .smp-modal-closeBtn {
      position: absolute;
      z-index: 90;
      font-size: 1rem;
      color: @color-mono-20;
      cursor: pointer;
      top: -@space-4;
      right: -@space-4;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: @color-mono-100;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3),
      0 3px 1px -2px rgba(0, 0, 0, 0.5),
      0 1px 5px 0 rgba(0, 0, 0, 0.3);
      transform: translate(50%, -50%);
    }
  }

  .smp-modal-content {
    .flex-auto;
    overflow-y: auto;
  }

  .smp-modal-footer {
    text-align: center;
    .flex-none;

    > :first-child:not(:last-child) {
      margin-right: @space-2;
    }
  }
</style>
