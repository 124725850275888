<template>
  <div
    class="smp-checkbox"
    :class="[{disabled: disabled}, {checked: value}]">
    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @change="toggle($event.target.checked)"
      :id="id">
    <label :for="id">
      <slot>Label</slot>
    </label>
    <div class="smp-checkbox-icon">
      <fa-icon
        v-show="value"
        :icon="['fas', 'check']" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="less">
  @import (reference) '~@/styles/vars';
  .smp-checkbox {
    position: relative;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    input {
      visibility: hidden;
      display: none;
    }

    label {
      padding-left: 35px;
      color: inherit;
      text-transform: none;
      position: relative;
      z-index: 9;
      margin-bottom: 0;
      display: block;
      min-height: 20px;
      line-height: 20px;
      cursor: pointer;
    }

    .smp-checkbox-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      z-index: 5;
      left: 0;
      border: 2px solid;
      transition: color 250ms ease;
    }
  }
</style>
