<template>
  <div class="smp-accountPage">
    <template v-if="userProfile">
      <div class="smp-accountPage-section">
        <h3>
          {{ $t('userProfile.personalInformation.headline') }}
        </h3>

        <div class="smp-form">
          <div class="smp-accountPage-field">
            <div class="field">
              <label>{{ $t('userProfile.personalInformation.name') }}</label>
              <div class="field-inputWrapper">
                <input
                  type="text"
                  readonly
                  :value="form.email">
              </div>
            </div>
          </div>
          <div class="smp-accountPage-field">
            <div class="field">
              <label>{{ $t('userProfile.personalInformation.company') }}</label>
              <div class="field-inputWrapper">
                <input
                  type="text"
                  readonly
                  :value="form.company">
              </div>
            </div>
          </div>
          <div class="smp-accountPage-field">
            <div class="field">
              <label>{{ $t('userProfile.personalInformation.email') }}</label>
              <div class="field-inputWrapper">
                <input
                  type="text"
                  readonly
                  :value="form.email">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="smp-accountPage-section">
        <h3>
          {{ $t('userProfile.password.headline') }}
        </h3>

        <ValidationObserver
          ref="passwordValidationObserver"
          class="smp-form"
          tag="div">
          <ValidationProvider
            :name="$t('userProfile.password.password')"
            v-slot="{ errors }"
            rules="required|password"
            mode="eager"
            tag="div"
            class="field">
            <label
              for="passwordField">
              {{ $t('userProfile.password.password') }}
              <span
                v-if="errors.length > 0"
                class="smp-validationError">
                {{ errors[0] }}
              </span>
            </label>

            <div
              class="field-inputWrapper"
              :class="{ invalid: errors.length > 0 }">
              <input
                ref="input"
                id="passwordField"
                type="password"
                name="passwordField"
                v-model="form.password.newPassword"
                autocomplete="off"
                autocapitalize="off">
            </div>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :rules="`required|passwordconfirmation:@${$t('userProfile.password.password')}`"
            :name="$t('userProfile.password.passwordConfirmation')"
            mode="aggressive"
            tag="div"
            class="field">
            <label
              for="newPasswordConfirmation">
              {{ $t('userProfile.password.passwordConfirmation') }}
              <span
                v-if="errors.length > 0"
                class="smp-validationError">
                {{ errors[0] }}
              </span>
            </label>

            <div
              class="smp-field-inputWrapper"
              :class="{ invalid: errors.length > 0 }">
              <input
                id="newPasswordConfirmation"
                type="password"
                name="newPasswordConfirmation"
                autocomplete="off"
                autocapitalize="off"
                v-model="form.password.passwordConfirmation">
            </div>
          </ValidationProvider>

          <div>
            <a
              href="#"
              :disabled="!form.password.newPassword || !form.password.passwordConfirmation"
              class="smp-button smp-button-primary"
              @click.prevent="changePassword">

              <fa-icon
                v-if="isSavingPassword"
                class="mr2"
                icon="spinner"
                spin />

              <fa-icon
                v-if="!isSavingPassword"
                class="mr2"
                icon="save" />

              {{ $t('userProfile.password.saveCta') }}
            </a>
          </div>
        </ValidationObserver>
      </div>

      <div
        v-if="accessPermissionsForUser.length > 0"
        class="smp-accountPage-section">
        <h3>{{ $t('userProfile.permissions.headline') }}</h3>
        <p class="ms-1">
          {{ $t('userProfile.permissions.content') }}
        </p>

        <div
          class="inline-block"
          v-for="permission of accessPermissionsForUser"
          :key="permission.id">
          <button
            v-if="permission.userMayChange"
            class="smp-button flex items-center"
            @click="togglePermission(permission)">
            <i class="mr2">
              <fa-icon
                v-if="pendingPermissionChange"
                :fixed-width="true"
                :icon="['fas', 'spinner']"
                spin />
              <fa-icon
                v-else
                :fixed-width="true"
                :icon="['far', userHasPermission(permission.id) ? 'check-square' : 'square']" />
            </i>

            <tooltip
              v-if="permission.info"
              placement="right"
              :content="permission.info"
              :delay="{ show: 500, hide: 0 }">
              <span class="ms-1">
                {{ permission.title }}
                <i>
                  <fa-icon :icon="['fas', 'info-circle']" />
                </i>
              </span>
            </tooltip>

            <span
              v-else
              class="ms-1">
              {{ permission.title }}
            </span>
          </button>

          <div
            v-else
            class="smp-chip flex items-center ms-1 mx1">
            <i class="mr2">
              <fa-icon :icon="['fas', 'check']" />
            </i>

            <span>
              {{ permission.title }}
            </span>

            <tooltip
              v-if="permission.info"
              placement="right"
              :content="permission.info"
              :delay="{ show: 500, hide: 0 }">
              <i class="ml1">
                <fa-icon :icon="['fas', 'info-circle']" />
              </i>
            </tooltip>
          </div>
        </div>
      </div>

      <div
        v-if="notificationPermissionState && supportsNotifications"
        class="smp-accountPage-section">
        <h3>{{ $t('userProfile.notifications.headline') }}</h3>

        <div class="flex items-center">
          <fa-icon
            :icon="notificationIcon"
            :class="notificationClass" />
          <p class="ms-1 ml2">
            {{ $t(`userProfile.notifications.state.${notificationPermissionState}`) }}
          </p>
        </div>

        <p v-if="notificationPermissionState === 'default'">
          <button
            class="smp-button smp-button-primary"
            :disabled="notificationRequestPending"
            @click.prevent="requestNotificationPermission">
            <fa-icon
              icon="spinner"
              spin
              v-if="notificationRequestPending" />
            <template v-else>
              {{ $t('userProfile.notifications.requestCta') }}
            </template>
          </button>
        </p>
      </div>
    </template>

    <div
      v-else
      class="smp-accountPage-error">
      {{ $t('userProfile.missingProfile') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { addUserLog, logout } from '@/util/firebase';
import { backendService } from '@/services/BackendService';
import logger from '@/util/logger';
import Tooltip from '@/components/Tooltip.vue';
import { gridFetchRequired$, notificationsEnabled$ } from '@/util/subjects';

export default {
  name: 'Account',
  components: { Tooltip },
  data() {
    return {
      form: {
        name: null,
        company: null,
        password: {
          newPassword: null,
          passwordConfirmation: null,
        },
      },
      isSavingPassword: false,
      pendingPermissionChange: false,
      notificationPermissionState: null,
      notificationRequestPending: false,
    };
  },
  computed: {
    ...mapState(['supportsNotifications']),
    ...mapState('user', ['user', 'userProfile']),
    ...mapGetters(['accessPermissions', 'currentLang']),
    accessPermissionsForUser() {
      return this.accessPermissions
        .filter((permission) => permission.userMayChange
          || this.userProfile.permissionIds.includes(permission.id))
        .map((permission) => {
          const info = (() => {
            const targetLang = Object
              .keys(permission.info)
              .find((lang) => lang.startsWith(`${this.currentLang}_`));

            return targetLang ? permission.info[targetLang] : null;
          })();

          return {
            ...permission,
            info,
          };
        });
    },
    notificationIcon() {
      if (this.notificationPermissionState === 'default') {
        return 'question-circle';
      }

      if (this.notificationPermissionState === 'granted') {
        return 'check-circle';
      }

      return 'exclamation-triangle';
    },
    notificationClass() {
      if (this.notificationPermissionState === 'default') {
        return 'warn';
      }

      if (this.notificationPermissionState === 'granted') {
        return 'success';
      }

      return 'danger';
    },
  },
  watch: {
    userProfile() {
      this.setFromUserProfile();
    },
  },
  created() {
    if (this.supportsNotifications) {
      this.notificationPermissionState = Notification.permission;
    }
  },
  mounted() {
    this.setFromUserProfile();
  },
  methods: {
    setFromUserProfile() {
      this.form.name = this.userProfile?.name;
      this.form.company = this.userProfile?.company;
      this.form.email = this.userProfile?.email;
    },
    async logout() {
      await logout();
    },
    async changePassword() {
      const validationResult = await this.$refs.passwordValidationObserver.validate();
      if (!validationResult) return;

      this.isSavingPassword = true;

      try {
        await backendService.updateUser({
          password: this.form.password.newPassword,
        });

        if (this.$refs.passwordValidationObserver) {
          this.$refs.passwordValidationObserver.reset();
        }

        this.form.password.newPassword = null;
        this.form.password.passwordConfirmation = null;

        this.$notification.success(this.$t('userProfile.password.successMessage'));
        addUserLog('changed password');
      } catch (e) {
        this.handleSaveError(e);
      }

      this.isSavingPassword = false;
    },
    handleSaveError(e) {
      logger.captureException(e);

      let message = e?.data?.message ?? 'generic_error';

      const messageKey = `generic.serverMessages.${message}`;
      if (this.$t(messageKey) !== messageKey) {
        message = this.$t(messageKey);
      }

      this.$notification.error(message);
    },
    userHasPermission(permissionId) {
      return this.userProfile.permissionIds?.includes(permissionId);
    },
    togglePermission(permission) {
      this.pendingPermissionChange = true;
      const userHasPermission = this.userHasPermission(permission.id);

      setTimeout(() => {
        backendService.updateUser({
          permissionId: permission.id,
          enable: !userHasPermission,
        })
          .then(() => {
            addUserLog(`Turned access permission "${permission.title}" ${userHasPermission ? 'off' : 'on'}`);
          })
          .finally(() => {
            this.pendingPermissionChange = false;
            gridFetchRequired$.next(true);
          });
      }, 200);
    },
    async requestNotificationPermission() {
      this.notificationRequestPending = true;

      try {
        Notification.requestPermission()
          .then((result) => {
            this.notificationPermissionState = result;
            if (result === 'granted') {
              notificationsEnabled$.next(true);
            }
            this.notificationRequestPending = false;
          });
      } catch (e) {
        logger.captureException(e);
        this.notificationRequestPending = false;
      }
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-accountPage {
  width: 540px;
  max-width: 100%;

  .smp-accountPage-error {
    color: @color-error;
    font-weight: bold;
    margin-top: @space-3;
  }

  .smp-accountPage-section {
    &:not(:last-child) {
      margin-bottom: @space-5;
    }
  }

  .smp-localeMenu {
    max-width: 200px;
  }

  .smp-chip {
    height: 32px;
    background-color: fadeout(@color-primary, 85);
    padding: 16px 8px;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  svg {
    &.success path {
        fill: @color-success;
    }

    &.warn path {
      fill: @color-warn;
    }

    &.danger path {
      fill: @color-error;
    }
  }
}
</style>
