import { mapGetters, mapActions } from 'vuex';

import { shouldCloseOnClick } from '@/util/utils';

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters(['activeDropDownFilter']),
  },
  watch: {
    activeDropDownFilter(uid) {
      if (this._uid !== uid) this.hide();
    },
    isOpen(val) {
      if (val) {
        window.addEventListener('click', this.clickListener, false);
      } else {
        window.removeEventListener('click', this.clickListener, false);
      }
    },
  },
  methods: {
    ...mapActions(['setActiveDropDownFilter']),
    open() {
      this.isOpen = true;
      this.setActiveDropDownFilter(this._uid);
    },
    show() {
      this.open();
    },
    close() {
      this.isOpen = false;
    },
    hide() {
      this.close();
    },
    toggle() {
      if (this.isOpen) this.close();
      else this.open();
    },
    clickListener(e) {
      if (shouldCloseOnClick(e, this.containerClass)) { this.close(); }
    },
  },
};
