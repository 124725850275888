<template>
  <div
    class="smp-datePicker"
    :class="{'is-open': isOpen}">
    <v-date-picker
      ref="datePicker"
      v-model="date"
      :locale="currentLang"
      mode="date"
      :available-dates="matchDays"
      @input="dateChanged"
      @popoverWillShow="appear"
      @popoverWillHide="disappear">
      <template v-slot="{ inputValue, inputEvents }">
        <button
          v-on="inputEvents"
          class="smp-button smp-button-primary smp-datePicker-toggleButton items-center">
          <div class="flex-auto flex items-center">
            <i class="mr2">
              <fa-icon :icon="['far', 'calendar-alt']" />
            </i>

            <template v-if="!date">
              {{ $t('filter.date.title') }}
            </template>
            <template v-else>
              {{ formattedDate }}
            </template>
          </div>
          <div class="ml1 smp-datePicker-toggleIndicator">
            <fa-icon :icon="['fas', 'angle-down']" />
          </div>
        </button>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import Vue from 'vue';
import VCalendar from 'v-calendar';
import { mapGetters } from 'vuex';
import '@/../node_modules/v-calendar/src/styles/base.css';
import moment from '@/util/moment';

Vue.use(VCalendar, {
  datePicker: {
    popover: {
      visibility: 'click',
    },
  },
});

export default {
  data() {
    return {
      date: null,
      isOpen: false,
    };
  },
  created() {
    // Check for date value in the store, update the locale value
    if (this.storeDate && this.storeDate !== this.formattedDate) {
      this.setDate(this.storeDate);
    }
  },
  watch: {
    // Watch for date changes in the store (triggered somewhere else) and update the local value
    storeDate(newVal) {
      if (newVal !== this.formattedDate) {
        this.setDate(newVal);
      }
    },
  },
  computed: {
    ...mapGetters({
      storeDate: 'date',
    }),
    ...mapGetters(['matchDays', 'currentLang']),

    /**
     * Get the current date as moment object, independent from the timezone
     * @return {*}
     */
    momentDate() {
      if (!this.date) return null;

      return moment({
        day: this.date.getDate(),
        month: this.date.getMonth(),
        year: this.date.getFullYear(),
      });
    },
    formattedDate() {
      return this.momentDate ? this.momentDate.format(moment.getDateFormat()) : null;
    },
  },
  methods: {
    dateChanged() {
      this.$emit('changed', this.formattedDate);
    },
    setDate(date) {
      if (date === null) {
        this.clear();
      } else {
        this.date = moment.utc(date).toDate();
      }
    },
    clear() {
      this.date = null;
    },
    appear() {
      this.isOpen = true;
      this.$store.dispatch('setActiveDropDownFilter', this._uid);
    },
    disappear() {
      this.isOpen = false;
      this.$store.dispatch('setActiveDropDownFilter', null);
    },
  },
};
</script>

<style lang="less">
@import (reference) '~@/styles/base';

.smp-datePicker {
  position: relative;

  .smp-datePicker-toggleButton {
    display: flex;
    .ms(-1);

    i:first-child {
      .ms(0);
    }
  }

  &.is-open {
    .smp-datePicker-toggleIndicator {
      transform: rotate(-180deg);
    }
  }

  .smp-datePicker-toggleIndicator {
    transition: transform 250ms ease-in-out;
    display: inline-block;
  }

  .vc-popover-content {
    border-color: @color-primary;
    background: @color-primary;

    // inner element for month selection
    .vc-popover-content {
      background: lighten(@color-primary, 10);

      .vc-nav-arrow, .vc-nav-item, .vc-nav-title {
        &:hover {
          background: @color-primary;
        }
      }

      .vc-nav-item.is-active {
        background: @color-primary;
        color: @color-mono-100;
      }
    }

    .vc-container {
      background: @color-primary;
      color: @color-mono-100;

      .vc-title, .vc-arrow, .vc-weekday {
        color: @color-mono-100;
      }

      .vc-arrow {
        &:hover {
          background: desaturate(lighten(@color-primary, 30), 50);
        }
      }

      .vc-day-content {
        &:hover {
          background: desaturate(lighten(@color-primary, 30), 50);
        }
        &.is-disabled {
          color: @color-mono-50;
        }
      }

      .vc-highlights {
        .vc-highlight {
          background: @color-secondary !important;
        }
      }
    }
  }
}
</style>
