import Vue from 'vue';
import {
  ValidationProvider, ValidationObserver, extend, setInteractionMode, configure,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { i18n } from '@/util/i18n';
import ValidatedInput from '@/components/form/ValidatedInput.vue';

setInteractionMode('eager');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidatedInput', ValidatedInput);

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => i18n.t(`validation.messages.${values._rule_}`, values),
});

// Load only the required validation rules
['required', 'email'].forEach((ruleName) => {
  extend(ruleName, {
    ...rules[ruleName],
  });
});

extend('passwordconfirmation', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
});

extend('password', {
  params: ['min'],
  validate(value, { min }) {
    if (!min) {
      min = 8;
    }

    if (!value || typeof value !== 'string') {
      return false;
    }

    if (value.length < 8) {
      return false;
    }

    if (!value.match(/\d/)) {
      return false;
    }

    if (!value.match(/[a-z|A-Z]/)) {
      return false;
    }

    return true;
  },
});

const getInputEventValue = (event) => {
  let value = null;
  if (event.target.type === 'checkbox') value = event.target.checked;
  else if (event.target.type === 'number') value = parseInt(event.target.value);
  else value = event.target.value;

  return value;
};

Vue.mixin({
  methods: {
    onInput(event) {
      const value = getInputEventValue(event);

      let target = this.form;
      const parts = event.target.name.split('.');
      const key = parts.pop();

      if (parts.length > 0) {
        parts.forEach((part) => {
          target = target[part];
        });
      }

      target[key] = value;
    },
    onInputStore(event) {
      const value = getInputEventValue(event);
      this.$store.dispatch('updateForm', {
        target: `form.${event.target.name}`,
        value,
      });
    },
  },
});
