<template>
  <div class="smp-mainPage">
    <main-header
      ref="header"
      @triggerUpdate="triggerUpdateOnClick" />

    <div
      class="smp-noResultsHint mx-auto center"
      v-if="postingsTotal === 0 && gridInited">
      <h2>{{ $t('generic.noResults') }}</h2>
      <button
        class="smp-button smp-button-primary smp-button--large"
        @click.prevent="resetFilters">
        {{ $t('filter.reset') }}
      </button>
    </div>

    <div
      ref="gridWrapper"
      class="smp-postingGrid-wrapper smp-mainPage-contentWrapper flex-auto">
      <grid
        ref="grid"
        @scroll-top="$refs.scrollTop.scrollTop()" />
    </div>

    <preview
      v-if="activePosting"
      :posting="activePosting"
      @close="$store.dispatch('closePreview')" />

    <scroll-top
      ref="scrollTop"
      :elements="$refs" />

    <!-- Modal for the mobile filter menu -->
    <mobile-filter-menu
      v-if="mobileFilterMenuVisible"
      @close="hideMobileFilterMenu" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Velocity from 'velocity-animate';
import MainHeader from '@/components/MainPageHeader.vue';
import Grid from '@/components/PostingGrid.vue';
import Preview from '@/components/PostingPreview.vue';
import LogService from '@/services/LogService';
import ScrollTop from '@/components/ScrollGridToTop.vue';
import MobileFilterMenu from '@/components/MobileFilterMenu.vue';
import { postingsToAdd$ } from '@/util/subjects';

export default {
  data() {
    return {
      postingsToAdd: [],
      postingsToAddSubscription: null,
    };
  },
  components: {
    MainHeader, Grid, Preview, ScrollTop, MobileFilterMenu,
  },
  computed: {
    ...mapState(['gridInited']),
    ...mapGetters([
      'activePosting',
      'updateAvailable',
      'fetchParams',
      'postingsTotal',
      'mobileFilterMenuVisible',
    ]),
  },
  mounted() {
    this.postingsToAddSubscription = postingsToAdd$.subscribe((postings) => {
      if (postings) {
        this.postingsToAdd = this.postingsToAdd.concat(postings);

        // Trigger auto-update if we are on the top
        if (this.$refs.gridWrapper.scrollTop === 0) {
          this.triggerAutoUpdate();
        } else {
          this.setUpdateAvailableStatus(true);
        }
      }
    });
  },
  beforeDestroy() {
    if (this.postingsToAddSubscription) {
      this.postingsToAddSubscription.unsubscribe();
    }
  },
  methods: {
    ...mapActions([
      'setUpdateAvailableStatus',
      'resetFilters',
      'hideMobileFilterMenu',
      'addPostings',
    ]),
    triggerUpdateOnClick() {
      LogService.event('Navigation', 'updateOnClick');
      this.triggerUpdate();
    },
    triggerAutoUpdate() {
      LogService.event('Navigation', 'autoUpdate');
      this.triggerUpdate();
    },
    triggerUpdate() {
      this.setUpdateAvailableStatus(false);

      if (this.postingsToAdd.length > 0) {
        this.addPostings(this.postingsToAdd);

        this.$nextTick(() => {
          const { grid, gridWrapper, header } = this.$refs;
          const vm = this;

          if (gridWrapper.scrollTop > 0) {
            Velocity(grid.$el, 'scroll', {
              duration: 400,
              offset: -(header.$el.getBoundingClientRect().height + 24),
              container: gridWrapper,
              complete() {
                grid.layoutNewPostings(vm.postingsToAdd, true);
                vm.postingsToAdd = [];
              },
            });
          } else {
            grid.layoutNewPostings(this.postingsToAdd, true);
            this.postingsToAdd = [];
          }
        });
      }
    },
    reload() {
      window.location.reload();
    },
  },
  watch: {
    updateAvailable(newVal) {
      if (newVal) {
        // Trigger auto-update if we are on the top
        if (this.$refs.gridWrapper.scrollTop === 0) {
          this.setUpdateAvailableStatus(false);
          this.triggerAutoUpdate();
        }
      }
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

body {
  .smp-mainPage {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .smp-mainPageHeader {
      flex: none;
    }

    .smp-mainPage-contentWrapper {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    > .smp-mainPageHeader, .smp-mainPage-contentWrapper {
      transition: filter 250ms linear;
    }
  }

  &.overlay-open .smp-mainPage {
    > .smp-mainPageHeader, .smp-mainPage-contentWrapper {
      filter: blur(15px);
    }
  }
}

.smp-noResultsHint {
  padding-top: @space-7;
}

.smp-postingGrid-wrapper {
  padding: @space-4 @gridSpace;
}
</style>
