import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import pkg from '@/../package.json';

const useSentry = process.env.NODE_ENV === 'production';

/**
 * Logging helper class, using Sentry on production.
 *
 * @see https://docs.sentry.io/platforms/javascript/vue/
 */
export default {
  init() {
    if (useSentry) {
      Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        release: `${pkg.name}@${pkg.version}-${process.env.theme}`,
        environment: process.env.VUE_APP_ENVIRONMENT,
        beforeSend(event, hint) {
          if (event
            && event.exception
            && event.exception.values
            && Array.isArray(event.exception.values)
            && event.exception.values.length > 0) {
            const exceptionValue = event.exception.values[0];
            if (exceptionValue && exceptionValue.value && typeof exceptionValue.value === 'string') {
              if (exceptionValue.value.indexOf('ResizeObserver') > -1) {
                return null;
              }
            }
          }

          return event;
        },
      });
    }
  },
  captureException(err) {
    if (useSentry && err && err.stack && err.message) {
      Sentry.captureException(err);
    } else {
      console.log(err);
    }
  },
  captureMessage(message) {
    if (useSentry) {
      Sentry.captureMessage(message);
    } else {
      console.log(`[Sentry] ${message}`);
    }
  },
};
