import moment from 'moment-timezone';
import { defaultTimezone$, userTimezone$, creatorTimezone$ } from '@/util/subjects';

// Update moment default timezone if changed
defaultTimezone$.subscribe((tz) => {
  moment.tz.setDefault(tz);
});

// Define the default date format and get the users time zone passed from the server
const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DD H:mm:ss';
const userDateTimeFormat = 'D. MMM YYYY, HH:mm';
const userDateTimeFormatShort = 'D. MMM, HH:mm';

/**
 * Get the default date format passed from and to the server.
 * @return {String}
 */
moment.getDateFormat = () => dateFormat;

/**
 * Get the default date time format passed from and to the server.
 * @returns {String}
 */
moment.getDateTimeFormat = () => dateTimeFormat;

/**
 * Get the default date time format shown to users.
 * @return {string}
 */
moment.getUserDateTimeFormat = (short = false) => (short ? userDateTimeFormatShort : userDateTimeFormat);

/**
 * Change the passed moment object from the default to the users time zone.
 * @returns {moment}
 */
Object.getPrototypeOf(moment()).toUserTimezone = function () {
  return this.tz(userTimezone$.value);
};

/**
 * Change the passed moment object from the default to the users time zone.
 * @returns {moment}
 */
Object.getPrototypeOf(moment()).toCreatorTimezone = function () {
  return this.tz(creatorTimezone$.value);
};

/**
 * Get the locale from the payload if given.
 */
if (window.payload && window.payload.hasOwnProperty('currentLocale')) {
  moment.locale(window.payload.currentLocale.Code);
}

export default moment;
